import * as React from 'react'
import {ChangeEvent, ChangeEventHandler, useState} from 'react'
import {TextField} from "../../overloaded/form/TextField";
import {Button} from "../../overloaded/Button";
import {ApolloError, gql} from "apollo-boost";
import {useMutation} from "@apollo/react-hooks";
import {extend, omit} from 'lodash'
import {engine} from "../../model/MyEngine";
import {LoaderContainer} from "../../LoaderContainer";
import {ResultCode} from "../../ResultCode";

class FormValues {
    username: string = '';
    password: string = '';
    password2: string = '';
    email: string = '';
}

class Errors extends FormValues {
}


export const SignupForm = () => {
    const [signup, {data, loading}] = useMutation(SIGNUP);
    const [errors, setErrors] = useState<FormValues>(new Errors());
    const [values, setValues] = useState<FormValues>(new FormValues());


    !loading && data?.signup?.username && engine.fireEvent({}, 'signupComplete');

    return (
        <div onKeyUp={(ev) => ev.which === 13 && checkForm()}>
            <LoaderContainer loading={loading}>
                <TextField error={!!errors.username} autoFocus helperText={errors.username} onChange={setValue('username')} label="Username" fullWidth style={{marginBottom: 10}}/>
                <TextField error={!!errors.password} helperText={errors.password} onChange={setValue('password')} label="Password" type="password" fullWidth style={{marginBottom: 10}}/>
                <TextField error={!!errors.password2} helperText={errors.password2} onChange={setValue('password2')} label="Confirm Password" type="password" fullWidth style={{marginBottom: 10}}/>
                <TextField onChange={setValue('email')} label="Email" fullWidth style={{marginBottom: 10}}/>
                <Button onClick={checkForm}>Sign up</Button>
            </LoaderContainer>
        </div>
    );

    function checkForm() {
        const e: Errors = new Errors();
        values.username.length || (e.username = 'Username required');
        values.password.length || (e.password = 'Password required');
        values.password !== values.password2 && (e.password2 = 'Passwords do not match');
        if (e.username || e.password || e.password2) {
            setErrors(e);
        } else {
            setErrors(new Errors());
            signup({variables: {input: omit(values, 'password2')}}).catch((e: ApolloError) => {
                if (e.graphQLErrors[0].message === ResultCode.USERNAME_EXISTS) {
                    setErrors(extend(new Errors(), {username: 'Username already exists'}));
                } else {
                    setErrors(extend(new Errors(), {username: e.graphQLErrors[0].message}))
                }
            });
        }
    }

    function setValue(name: string): ChangeEventHandler {
        return (ev: ChangeEvent<HTMLInputElement>) => setValues(extend({}, values, {[name]: ev.target.value}));
    }

};


const SIGNUP = gql`
    mutation signup($input: SignupInput) {
        signup(input: $input) {
            username
        }
    }
`;