import * as React from 'react'
import IconButton from '@material-ui/core/IconButton';
import DeleteForever from '@material-ui/icons/DeleteForever';
import {BaseModel} from '@projectstorm/react-canvas-core'

interface ActionButtonsProperties {
    node: BaseModel
}

export const ActionButtons: React.FC<ActionButtonsProperties>  =  ({node}) => (
    <div style={{borderBottom: '1px solid #ddd', marginBottom: 10}}>
        <IconButton aria-label="upload picture" component="span" onClick={() => node.remove()}>
            <DeleteForever />
        </IconButton>
    </div>
);