import React from 'react'
import diagram from './diagram.png'

export const InstructionsPanel = () => (
    <div style={{padding: 10, width: 300, height: '100%', overflow: 'auto'}}>
        <h4>Welcome to circuit builder</h4>
        <p>
            To get started, drag an items from the panel on the left to the diagram area.
            You can create wire links between them using one of the wire handles around the
            outside of the icon that you will find when you hover over them with a mouse.
        </p>
        <p style={{textAlign: 'center'}}>
            <img src={diagram} alt="diagram example"/>
        </p>
        </div>
);