import React from "react";
import {Checker} from "./Checker";
import {MyDiagramModel} from "../../MyDiagramModel";
import {DesignFlaw, DesignWarning} from "../designChecker";
import {compareWireSize, WireSizeComparison} from "../../../compareWireSize";
import {Maybe, None, Some} from "monet";
import {ProductNodeModel} from "../../ProductNodeModel";
import {ProductLinkModel} from "../../ProductLinkModel";
import {engine} from "../../MyEngine";
import {registerChecker} from "../checkers";

interface NodeLinksTuple {
    node: ProductNodeModel,
    links: ProductLinkModel[]
}



registerChecker(new class CheckWireSizeToEndDevices extends Checker {
    run(model: MyDiagramModel): DesignFlaw[] {
        const flaws: DesignFlaw[] = [];
        model.getNodes().forEach((node) => {
            Maybe.of(node)
                .flatMap(nodeHasMinWireGauge)
                .map(node => ({node, links: getNodeLinks(node)}))
                .flatMap(({node, links}): Maybe<NodeLinksTuple>  =>
                    links.every(link => link.data.wireGauge) ? Maybe.some({node, links}) : None()
                )
                .flatMap(({node, links}): Maybe<ProductNodeModel> =>
                    links.find(link => compareWireSize(link.data.wireGauge).to(node.product.specs.minWireGauge) === WireSizeComparison.SMALLER) ? Some(node) : None()
                )
                .map(node =>
                    flaws.push(new DesignWarning(widget(node)))
                )
        });
        return flaws;
    }

}());

const widget = (node:ProductNodeModel) => () => (
    <div
        onClick={() => {
            node.setSelected(true);
            engine.repaintCanvas();
        }}
    >
        <div>Wire size to {node.product.name} is smaller than recommended ({node.product.specs.minWireGauge})</div>
    </div>);

const nodeHasMinWireGauge = (node: ProductNodeModel): Maybe<ProductNodeModel> => node.product.specs.minWireGauge ? Maybe.of(node) : None();
const getNodeLinks = (node: ProductNodeModel): ProductLinkModel[] => Object.values(node.getLinks()) as ProductLinkModel[];