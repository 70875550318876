import {RightAngleLinkWidget} from '@projectstorm/react-diagrams'
import React from 'react'
import {LinkSegmentWidget} from "./LinkSegmentWidget";

export class LinkWidget extends RightAngleLinkWidget {

    generateLink(path: string, extraProps: any, id: string | number): JSX.Element {
        const ref = React.createRef<SVGPathElement>();
        this.refPaths.push(ref);
        return (
            <LinkSegmentWidget
                key={`link-${id}`}
                path={path}
                selected={this.state.selected}
                diagramEngine={this.props.diagramEngine}
                factory={this.props.diagramEngine.getFactoryForLink(this.props.link)}
                link={this.props.link}
                forwardRef={ref}
                onSelection={(selected: boolean) => {
                    this.setState({ selected: selected });
                }}
                extras={{...extraProps,  onMouseEnter: () => {}}} // Hack to remove default hover behavior
            />
        );

    }

    render() {
        // Don't draw link before mouse is moved
        if(this.props.link.getLastPoint().getPosition().x === 0 && this.props.link.getLastPoint().getPosition().y === 0) {
            return <div/>;
        }

        return super.render();
    }
}