import * as React from 'react'
import {useState} from 'react'
import {DiagramsList} from "./DiagramsList";
import {engine} from "../../model/MyEngine";
import {MyDiagramModel} from "../../model/MyDiagramModel";
import {ApolloQueryResult, gql} from "apollo-boost";
import {LoaderContainer} from "../../LoaderContainer";
import {client} from "../../client";
import {useQuery} from "@apollo/react-hooks";
import {LoginButton} from "../../header/LoginButton";

export const LoadDiagramPanel = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const {data} = useQuery(ME_QUERY);

    const loadDiagram = (name: string) => {
        setLoading(true);
        client.query({query: DIAGRAM_QUERY, variables: {name}})
            .then(({data}: ApolloQueryResult<any>) => {
                const record = JSON.parse(data.me.diagram.data);
                const model = new MyDiagramModel();
                model.deserializeModel(record.model, engine);
                engine.setModel(model);
                engine.fireEvent({}, 'modelChanged');
            })
    };

    return data?.me?.username ? (
        <div style={{padding: 10}}>
            <LoaderContainer loading={loading}>
                <p>Choose a diagram to load</p>
                <DiagramsList onChange={loadDiagram}/>
            </LoaderContainer>
        </div>
    ) : (
        <div style={{width: 200, padding: 10}}>
            <p>Loading a diagram requires you to be logged in</p>
            <LoginButton/>
        </div>
    )
};

const DIAGRAM_QUERY = gql`
    query DiagramQuery($name: ID!)
    {
        me {
            diagram(name: $name) {
                name
                description
                data
            }
        }
    }
`;

const ME_QUERY = gql`{me{username}}`;