import {LinkProperties} from "../model/ProductLinkModel";
import {wireSizeOptions} from "./ampacityTables";
import {get, toString, find} from 'lodash'

export const lookupWireGaugeForVoltageLoss:(data: LinkProperties) => number | undefined = (data: LinkProperties) => {
    const lengthTable = find((voltageLossTables as any)[data.voltLoss][data.volts], (t, length) =>
        parseInt(length, 10) >= (data.circuitLength as number) ? true : false
    );
    const wireGauge = find(lengthTable, (table, amp) =>
        parseInt(amp, 10) >= (data.amps as number) ? true : false
    );
    return wireGauge !== undefined ? parseInt(wireGauge, 10) : undefined;
};

// table object layout:
// percent:voltage:length = {amp: cable size}

const percents = [3, 10];
const voltages = [12, 24];
const lengths = [10, 15, 20, 25, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170];
const amps = [5, 10, 15, 20, 25, 30, 40, 50, 60, 70, 80, 90, 100];

const gauges: any = {
    3: {
        12: {
            10: [18, 14, 12, 10, 10, 10, 8, 6, 6, 6, 6, 4, 4].map(toString),
            15: [16, 12, 10, 10, 8, 8, 6, 6, 4, 4, 4, 2, 2].map(toString),
            20: [14, 10, 10, 8, 6, 6, 6, 4, 4, 2, 2, 2, 2].map(toString),
            25: [12, 10, 8, 6, 6, 6, 4, 4, 2, 2, 2, 1, 1].map(toString),
            30: [12, 10, 8, 6, 6, 4, 4, 2, 2, 1, 1, '1/0', '1/0'].map(toString),
            40: [10, 8, 6, 6, 4, 4, 2, 2, 1, '1/0', '1/0', '2/0', '2/0'].map(toString),
            50: [10, 6, 6, 4, 4, 2, 2, 1, '1/0', '2/0', '3/0', '3/0', '3/0'].map(toString),
            60: [10, 6, 6, 4, 2, 2, 1, '1/0', '2/0', '3/0', '3/0', '4/0', '4/0'].map(toString),
            70: [8, 6, 4, 2, 2, 1, '1/0', '3/0', '3/0', '4/0', '4/0'].map(toString),
            80: [8, 6, 4, 2, 2, 1, '1/0', '2/0', '3/0', '4/0', '4/0'].map(toString),
            90: [8, 4, 2, 2, 1, '1/0', '2/0', '3/0', '4/0', '4/0'].map(toString),
            100: [6, 4, 2, 2, 1, '1/0', '2/0', '3/0', '4/0'].map(toString),
            110: [6, 4, 2, 1, '1/0', '1/0', '3/0', '4/0', '4/0'].map(toString),
            120: [6, 4, 2, 1, '1/0', '2/0', '3/0', '4/0'].map(toString),
            130: [6, 2, 2, 1, '1/0', '2/0', '3/0', '4/0'].map(toString),
            140: [6, 2, 1, '1/0', '2/0', '3/0', '4/0'].map(toString),
            150: [6, 2, 1, '1/0', '2/0', '3/0', '4/0'].map(toString),
            160: [6, 2, 1, '1/0', '2/0', '3/0', '4/0'].map(toString),
            170: [6, 2, 1, '2/0', '3/0', '3/0', '4/0'].map(toString)
        },
        24: {
            10: [18, 18, 16, 14, 12, 12, 10, 10, 10, 8, 8, 8, 6].map(toString),
            15: [18, 16, 14, 12, 12, 10, 10, 8, 8, 6, 6, 6, 6].map(toString),
            20: [18, 14, 12, 10, 10, 10, 8, 6, 6, 6, 6, 4, 4].map(toString),
            25: [16, 12, 12, 10, 10, 8, 6, 6, 6, 4, 4, 4, 4].map(toString),
            30: [16, 12, 10, 10, 8, 8, 6, 6, 4, 4, 4, 2, 2].map(toString),
            40: [14, 10, 10, 8, 6, 6, 6, 4, 4, 2, 2, 2, 2].map(toString),
            50: [12, 10, 8, 6, 6, 6, 4, 4, 2, 2, 2, 1, 1].map(toString),
            60: [12, 10, 8, 6, 6, 4, 4, 2, 2, 1, 1, '1/0', '1/0'].map(toString),
            70: [12, 8, 6, 6, 4, 4, 2, 2, 1, 1, '1/0', '1/0', '2/0'].map(toString),
            80: [10, 8, 6, 6, 4, 4, 2, 2, 1, '1/0', '1/0', '2/0', '2/0'].map(toString),
            90: [10, 8, 6, 4, 4, 2, 2, 1, '1/0', '1/0', '2/0', '2/0', '3/0'].map(toString),
            100: [10, 6, 6, 4, 4, 2, 2, 1, '1/0', '2/0', '2/0', '3/0', '3/0'].map(toString),
            110: [10, 6, 6, 4, 2, 2, 1, '1/0', '1/0', '2/0', '3/0', '3/0', '4/0'].map(toString),
            120: [10, 6, 4, 4, 2, 2, 1, '1/0', '2/0', '3/0', '3/0', '4/0', '4/0'].map(toString),
            130: [8, 6, 4, 2, 2, 2, 1, '1/0', '2/0', '3/0', '3/0', '4/0', '4/0'].map(toString),
            140: [8, 6, 4, 2, 2, 1, '1/0', '2/0', '3/0', '3/0', '4/0', '4/0'].map(toString),
            150: [8, 6, 4, 2, 2, 1, '1/0', '2/0', '3/0', '3/0', '4/0', '4/0'].map(toString),
            160: [8, 6, 4, 2, 2, 1, '1/0', '2/0', '3/0', '4/0', '4/0', '4/0'].map(toString),
            170: [8, 6, 2, 2, 1, 1, '2/0', '3/0', '3/0', '4/0', '4/0'].map(toString)
        }
    },
    10: {
        12: {
            10: [18, 18, 18, 16, 16, 14, 14, 12, 12, 10, 10, 10, 10].map(toString),
            15: [18, 18, 16, 14, 14, 12, 12, 10, 8, 8, 8, 8].map(toString),
            20: [18, 16, 14, 14, 12, 12, 10, 8, 8, 8, 6, 6].map(toString),
            25: [18, 16, 14, 12, 12, 10, 10, 8, 8, 6, 6, 6].map(toString),
            30: [18, 14, 12, 12, 10, 10, 8, 8, 6, 6, 6, 6, 4].map(toString),
            40: [16, 14, 12, 10, 10, 8, 8, 6, 6, 6, 4, 4, 4].map(toString),
            50: [16, 12, 10, 10, 8, 8, 6, 6, 4, 4, 4, 2, 2].map(toString),
            60: [14, 12, 10, 8, 8, 6, 6, 4, 4, 2, 2, 2, 2].map(toString),
            70: [14, 10, 8, 8, 6, 6, 6, 4, 2, 2, 2, 2, 1].map(toString),
            80: [14, 10, 8, 8, 6, 6, 4, 4, 2, 2, 2, 1, 1].map(toString),
            90: [12, 10, 8, 6, 6, 6, 4, 2, 2, 2, 1, 1, '1/0'].map(toString),
            100: [12, 10, 8, 6, 6, 4, 4, 2, 2, 1, 1, '1/0', '1/0'].map(toString),
            110: [12, 8, 8, 6, 6, 4, 2, 2, 2, 1, '1/0', '1/0', '1/0'].map(toString),
            120: [12, 8, 6, 6, 4, 4, 2, 2, 1, 1, '1/0', '1/0', '2/0'].map(toString),
            130: [12, 8, 6, 6, 4, 4, 2, 2, 1, '1/0', '1/0', '2/0', '2/0'].map(toString),
            140: [10, 8, 6, 6, 4, 2, 2, 1, 1, '1/0', '2/0', '2/0', '2/0'].map(toString),
            150: [10, 8, 6, 4, 4, 2, 2, 1, '1/0', '1/0', '2/0', '2/0', '3/0'].map(toString),
            160: [10, 8, 6, 4, 4, 2, 2, 1, '1/0', '2/0', '2/0', '3/0', '3/0'].map(toString),
            170: [10, 6, 6, 4, 2, 2, 2, 1, '1/0', '2/0', '2/0', '3/0', '3/0'].map(toString)
        },
        24: {
            10: [18, 18, 18, 18, 18, 18, 16, 16, 14, 14, 14, 12, 12].map(toString),
            15: [18, 18, 18, 18, 16, 16, 14, 14, 12, 12, 12, 10, 10].map(toString),
            20: [18, 18, 18, 16, 16, 14, 14, 12, 12, 10, 10, 10, 10].map(toString),
            25: [18, 18, 16, 16, 14, 14, 12, 12, 10, 10, 10, 8, 8].map(toString),
            30: [18, 18, 16, 14, 14, 12, 12, 10, 10, 8, 8, 8, 8].map(toString),
            40: [18, 16, 14, 14, 12, 12, 10, 10, 8, 8, 8, 6, 6].map(toString),
            50: [18, 16, 14, 12, 12, 10, 10, 8, 8, 6, 6, 6, 6].map(toString),
            60: [18, 14, 12, 12, 10, 10, 8, 8, 6, 6, 6, 6, 4].map(toString),
            70: [16, 14, 12, 10, 10, 8, 8, 6, 6, 6, 6, 4, 4].map(toString),
            80: [16, 14, 12, 10, 10, 8, 8, 6, 6, 6, 4, 4, 4].map(toString),
            90: [16, 12, 10, 10, 8, 8, 6, 6, 6, 4, 4, 4, 2].map(toString),
            100: [16, 12, 10, 10, 8, 8, 6, 6, 4, 4, 4, 2, 2].map(toString),
            110: [14, 12, 10, 8, 8, 8, 6, 6, 4, 4, 2, 2, 2].map(toString),
            120: [14, 12, 10, 8, 8, 6, 6, 4, 4, 2, 2, 2, 2].map(toString),
            130: [14, 12, 10, 8, 8, 6, 6, 4, 4, 2, 2, 2, 2].map(toString),
            140: [14, 10, 8, 8, 6, 6, 6, 4, 2, 2, 2, 2, 1].map(toString),
            150: [14, 10, 8, 8, 6, 6, 4, 4, 2, 2, 2, 2, 1].map(toString),
            160: [14, 10, 8, 8, 6, 6, 4, 4, 2, 2, 2, 1, 1].map(toString),
            170: [12, 10, 8, 6, 6, 6, 4, 2, 2, 2, 2, 1, 1].map(toString)
        }
    }
};

export const voltageLossTables = percents.reduce((obj: any, percent, percentIdx) => {
    obj[percent] = voltages.reduce((obj: any, voltage, voltsIdx) => {
        obj[voltage] = lengths.reduce((obj: any, length, lengthsIdx) => {
            obj[length] = amps.reduce((obj: any, amp, ampsIdx) => {
                obj[amp] = wireSizeOptions.indexOf(get(gauges, `[${percent}][${voltage}][${length}][${ampsIdx}]`));
                obj[amp] === -1 && (obj[amp] = undefined);
                return obj;
            }, {});
            return obj;
        }, {});
        return obj;
    }, {});
    return obj;
}, {});




