import React from 'react'
import {Dictionary, map, isArray} from 'lodash'
import {MenuItem, StandardTextFieldProps, TextField as TF} from "@material-ui/core";


interface SelectFieldProps extends StandardTextFieldProps {
    options: (Dictionary<string | number> | string[] | number[])
    inline?: boolean
}

export const SelectField: (props: SelectFieldProps) => React.ReactElement = (props) => {
    const {inline: boolean, options, ...cleanProps} = props;

    const optionsArray = isArray(options) ? (
        map(options as any, (item: string | number) => [item, item])
    ) : (
        Object.keys(options).map(key => [options[key], key])
    );


    return (
        <div style={{marginBottom: 15, display: props.inline ? 'inline' : 'block'}}>
            <TF select  {...{...cleanProps, variant: 'outlined'}} >
                {optionsArray.map(([label, value] ) => (
                    <MenuItem key={value} value={value}>{label}</MenuItem>
                ))}
            </TF>
        </div>
    )
};