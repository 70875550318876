import * as React from "react";
import styled from "@emotion/styled";
import Tooltip from '@material-ui/core/Tooltip';
import {IfDefined} from "../IfDefined";
import {Amazon} from "../data/productList";
import {AmazonInfoBtn} from "../amazon/AmazonInfoBtn";
import {Product} from "../model/Product";

export const TrayItem = ({model}: { model: Product }) => {
    return (
        <TrayItemDiv>
            <div style={{display: 'inline-block'}}
                 draggable={true}
                 onDragStart={event => {
                     event.dataTransfer.setData('id', model.id);
                 }}
            >
                <Tooltip title={model.description} placement="right-start">
                    <img src={model?.icon?.img} width={model?.icon?.width} height={model?.icon?.height} alt={model.name}/>
                </Tooltip>
            </div>
            <IfDefined value={model.amazon}>
                {(amazon: Amazon) => (
                    <div style={{float: 'right', marginLeft: 10}}>
                        <AmazonInfoBtn amazon={amazon}/>
                    </div>
                )}
            </IfDefined>
            <div>
                {model.name}
            </div>
        </TrayItemDiv>
    )
};
const TrayItemDiv = styled.div`
		color: white;
		font-family: Helvetica, Arial;
		cursor: pointer;
		text-align: center;
		font-size: .7em;
		width: 100%;
	`;
