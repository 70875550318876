import {wireProductDefinitions} from "./products/wire/wireProductDefinitions";
import {extend} from 'lodash'
import {switchProductDefinitions} from "./products/switches/switchProductDefinitions";
import {fuseProductDefinitions} from "./products/fuses/fuseProductDefinitions";
import {genericProductDefinitions} from "./products/generic/genericProductDefinitions";
import {busProductDefinitions} from "./products/busses/busProductDefinitions";
import {shuntProductDefinitions} from "./products/shunts/shuntProductDefinitions";
import {breakerProductDefinitions} from "./products/breakers/breakerProductDefinitions";
import {chargingProductDefinitions} from "./products/charging/chargingProductDefinitions";

import {ProductType} from "./ProductType";
import {inverterProductDefinitions} from "./products/inverters/inverterProductDefinitions";
import {Product} from "../model/Product";
import {lugProductDefinitions} from "./products/lugs/lugProductDefinitions";

export interface ProductPort {
    name: string
    alignment: any
    height: number
    width: number
    position: {
        top?: number,
        left?: number,
        right?: number,
        bottom?: number
    }
}


export interface ProductIcon {
    height: number
    width: number
    img: string
    ports: Array<ProductPort>,
    label: string,
    labelPosition: string
}

export interface ProductList {
    [key: string]: Product
}

export interface Amazon {
    textLink: string,
    textAndImage: string
}

export const wireProductsLookup = [18, 16, 14, 12, 10, 8, 6, 4, 3, 2, 1, '1/0', '2/0', '3/0', '4/0'].reduce((obj, gauge) => {
    return extend(obj,
        ['red', 'yellow', 'black', 'green', 'white'].reduce((memo, color) => {
            return extend(memo, {[`${gauge}:${color}`]: `anchor-wire-${gauge}-${color}`})
        }, {} as any)
    )
}, {} as any);

export interface PortDefinition {
    name: string
    iconHeight: number
    iconWidth: number
}

export interface ProductDefinition {
    id: string,
    type: ProductType,
    category: string,
    name: string,
    description: string,
    brand: string
    modelNo: string
    icon: string
    portFactory: Function
    amazon?: Amazon,
    specs?: {
        ampDraw?: number,
        minWireGauge?: string,
        terminalType?: string
    },
    specWidget?: React.FC<any>
}


const productListGenerator: (productDefinitions: ProductDefinition[], productList?: ProductList) => Promise<ProductList> = async (productDefinitions, productList = {}) => {
    const p = productDefinitions.shift();
    if (!p) {
        return productList
    }

    productList[p.id] = new Product({
        id: p.id,
        type: p.type || ProductType.unknown,
        category: p.category,
        name: p.name,
        description: p.description,
        brand: p.brand,
        modelNo: p.modelNo,
        amazon: p.amazon,
        specs: {
            ampDraw: p.specs?.ampDraw || 0,
            minWireGauge: p.specs?.minWireGauge || '',
            terminalType: p.specs?.terminalType || ''
        },
        specWidget: p.specWidget,
        icon: await new Promise(resolve => {
            const el = document.createElement('img');
            el.onload = () => {
                resolve({
                    img: p.icon,
                    height: el.height,
                    width: el.width,
                    ports: p.portFactory({name: p.id, iconHeight: el.height, iconWidth: el.width}),
                    label: '',
                    labelPosition: 'above'
                });
            };
            el.src = p.icon;
        })
    });
    return productListGenerator(productDefinitions, productList);

};

export const generateProductList = () =>
    productListGenerator(wireProductDefinitions
        .concat(switchProductDefinitions)
        .concat(fuseProductDefinitions)
        .concat(breakerProductDefinitions)
        .concat(genericProductDefinitions)
        .concat(busProductDefinitions)
        .concat(shuntProductDefinitions)
        .concat(chargingProductDefinitions)
        .concat(inverterProductDefinitions)
        .concat(lugProductDefinitions)
    ).then((list) => extend(productList, list));


const productList: ProductList = {};

export const getProductList = () => productList;


