import {DiagramModel} from "@projectstorm/react-diagrams";
import {checkDesign} from "./designChecker/designChecker";
import {ProductNodeModel} from "./ProductNodeModel";

export class MyDiagramModel extends DiagramModel {

    checkDesign() {
        return checkDesign(this);
    }

    getNodes(): ProductNodeModel[] {
        return super.getNodes() as ProductNodeModel[];
    }
}