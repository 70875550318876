import * as React from 'react'
import {Checkbox, CheckboxProps, FormControlLabel} from "@material-ui/core";

interface CheckboxFieldProps extends CheckboxProps {
    label: string
}

export const CheckboxField = ({label, style, ...props}: CheckboxFieldProps) => (
    <FormControlLabel
        control={
            <Checkbox {...props} style={{color: '#c3b3b7', ...style}}/>
        }
        label={label}
    />
);