import React, {ErrorInfo} from 'react'
import {engine} from "./model/MyEngine";
import {version} from "./version";

export class GlobalErrorBoundry extends React.Component{
    state = {
        error: null,
        info: null
    };

    componentDidCatch(error: Error, info: ErrorInfo) {
        this.setState({error, info});
    }

    render() {
        return this.state.error ? (
            <div>
            <h2>Sorry an error occurred.</h2>
                <h4>To recover your current state please copy the following text and paste into a file</h4>
                <pre>
                    {JSON.stringify({version, model: engine.getModel().serialize()})}
                </pre>
            </div>
        ) : this.props.children
    }
}