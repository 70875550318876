import {PortModelAlignment} from "@projectstorm/react-diagrams-core";
import {PortDefinition} from "../productList";

export const DiamondPortFactory = ({outside = false}) => ({name, iconHeight, iconWidth}: PortDefinition) => ([
    {
        name: `${name}-port-1`,
        alignment: PortModelAlignment.TOP,
        height: outside ? 6 : 12,
        width: 6,
        position: {
            left: iconWidth / 2 - 3,
            top: -6
        }
    }, {
        name: `${name}-port-2`,
        alignment: PortModelAlignment.RIGHT,
        height: 6,
        width: outside ? 6 : 12,
        position: {
            right: -6,
            top: iconHeight / 2 - 3
        }
    }, {
        name: `${name}-port-3`,
        alignment: PortModelAlignment.BOTTOM,
        height: outside ? 6 : 12,
        width: 6,
        position: {
            right: iconWidth / 2 - 3,
            bottom: -6
        }
    }, {
        name: `${name}-port-4`,
        alignment: PortModelAlignment.LEFT,
        height: 6,
        width: outside ? 6 : 12,
        position: {
            left: -6,
            top: iconHeight / 2 - 3
        }
    }
]);