import {DesignError, DesignFlaw} from "../designChecker";
import {registerChecker} from "../checkers";
import React from "react";
import {ProductLinkModel} from "../../ProductLinkModel";
import {engine} from "../../MyEngine";
import {ProductNodeModel} from "../../ProductNodeModel";

registerChecker({
    run: (model => (model.getLinks() as ProductLinkModel[])
            .reduce((flaws, link) => {
                return link.data.wireGauge ? flaws : flaws.concat([new DesignError(widget(link))]);
            }, [] as DesignFlaw[])
    )
});

const widget = (link: ProductLinkModel) => () => (
    <div onClick={() => {
        link.setSelected(true);
        engine.repaintCanvas()
    }}>
        <div>Link missing size</div>
        <div>
            "{(link.getSourcePort().getNode() as ProductNodeModel).product.name}"
            to
            "{(link.getTargetPort().getNode() as ProductNodeModel).product.name}"
        </div>
        <div>Please set wire gauge</div>
    </div>

);






