import * as React from 'react'
import {ChangeEvent, ChangeEventHandler, useState} from 'react'
import {TextField} from "../../overloaded/form/TextField";
import {ApolloError, gql} from "apollo-boost";
import {Button} from "../../overloaded/Button";
import {useMutation} from "@apollo/react-hooks";
import {extend} from "lodash";
import {ResultCode} from "../../ResultCode";
import {LoaderContainer} from "../../LoaderContainer";
import {engine} from "../../model/MyEngine";

class FormValues {
    username: string = '';
    password: string = '';
}

export const LoginForm = () => {
    const [login, {loading, data}] = useMutation(LOGIN);
    const [values, setValues] = useState<FormValues>(new FormValues());
    const [error, setError] = useState<string>('');


    if (data) {
        localStorage.setItem('token', data.login.token);
        engine.fireEvent({}, 'loginSuccessful')

    }
    return (
        <div onKeyUp={(ev) => ev.which === 13 && checkForm()}>
            <LoaderContainer loading={loading}>
                <TextField error={!!error} autoFocus helperText={error} onChange={setValue('username')} label="Username" fullWidth style={{marginBottom: 10}}/>
                <TextField label="Password" type="password" onChange={setValue('password')} fullWidth style={{marginBottom: 10}}/>
                <Button onClick={checkForm}>Log in</Button>
            </LoaderContainer>
        </div>
    );

    function checkForm() {
        let e;
        values.username.length || (e = 'Username required');
        if (e) {
            setError(e);
        } else {
            setError('');
            login({variables: values})
                .catch((e: ApolloError) => {
                    if (e.graphQLErrors[0]?.message === ResultCode.USER_NOT_EXIST || e.graphQLErrors[0]?.message === ResultCode.LOGIN_FAILED) {
                        setError('Invalid username or password')
                    } else {
                        setError(e.graphQLErrors[0].message)
                    }
                })
        }

    }

    function setValue(name: string): ChangeEventHandler {
        return (ev: ChangeEvent<HTMLInputElement>) => setValues(extend({}, values, {[name]: ev.target.value}));
    }

};


const LOGIN = gql`
    mutation login($username: String, $password: String) {
        login(username: $username, password: $password) {
            token
        }
    }
`;