import {AllAroundPortFactory} from "../../portFactories/allAroundPortFactory";
import {DiamondPortFactory} from "../../portFactories/diamondPortFactory";
import {PredefinedPortFactory} from "../../portFactories/PredefinedPortFactory";
import {ProductType} from "../../ProductType";

import BatteryIcon from './generic-battery.png'
import {GenericBatterySpecWidget} from "./GenericBatterySpecWidget";

export const genericProductDefinitions = [
    {
        id: 'generic-product',
        type: ProductType.unknown,
        category: 'Generic',
        name: 'Component',
        description: 'Generic Product',
        icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4wwRFxQz+UYdwQAAAERJREFUaN7tzzERAAAIBCC1f+e3hYMHDegkqQemnhARERERERERERERERERERERERERERERERERERERERERERERERG5sTzEBGDcoXAtAAAAAElFTkSuQmCC',
        portFactory: AllAroundPortFactory,
        brand: '',
        modelNo: ''
    }, {
        id: 'generic-endpoint',
        type: ProductType.unknown,
        category: 'Generic',
        name: 'Endpoint',
        description: 'endpoint',
        icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4wwRFRoZv/ot9wAAABlJREFUGNNj/P///38GIgATA5FgVCF1FAIAaZoEEKwrfnAAAAAASUVORK5CYII=',
        portFactory: DiamondPortFactory({outside: true}),
        brand: '',
        modelNo: ''
    }, {
        id: 'generic-battery',
        type: ProductType.POWER_SOURCE,
        brand: '',
        category: 'Generic',
        name: 'battery',
        description: 'Battery',
        icon: BatteryIcon,
        modelNo: 'generic-battery',
        specWidget: GenericBatterySpecWidget,
        portFactory: PredefinedPortFactory([{
            height: 14,
            width: 8,
            name: 'negative',
            position: {
                right: 5,
                top: -8
            }
        }, {
            name: 'positive',
            height: 14,
            width: 8,
            position: {
                left: 5,
                top: -8
            }
        }])
    }
];