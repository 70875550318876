import {ProductDefinition} from "../../productList";
import blueSeaAnlFuseBlockSmall from "./blue-sea-anl-fuse-block-small.png";
import {PredefinedPortFactory} from "../../portFactories/PredefinedPortFactory";
import {ProductType} from "../../ProductType";

export const fuseProductDefinitions: ProductDefinition[] = [{
    id: 'anl-fuse-block',
    type: ProductType.FUSE,
    brand: 'Blue Sea',
    category: 'Fuses',
    name: 'ANL Fuse block (35-300a)',
    description: 'Blue Sea Systems ANL Fuse Block with Insulating Cover - 35-300A',
    modelNo: '',
    icon: blueSeaAnlFuseBlockSmall,
    amazon: {
        textLink: 'https://www.amazon.com/Blue-Sea-Systems-Block-Insulating/dp/B000K2K7TW/ref=as_li_ss_tl?dchild=1&keywords=anl+fuse+holder+blue+sea&qid=1576774498&sr=8-1&linkCode=ll1&tag=twoblokes-20&linkId=2f31ed0a1f2801c0b11653774b36b81a&language=en_US',
        textAndImage: '<iframe style="width:120px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=twoblokes-20&language=en_US&marketplace=amazon&region=US&placement=B000K2K7TW&asins=B000K2K7TW&linkId=f5e80ea767c16fbf01be6308b8c0077f&show_border=true&link_opens_in_new_window=true"></iframe>'
    },
    specs: {
        terminalType: '5/16'
    },
    portFactory: PredefinedPortFactory([{
        height: 14,
        width: 8,
        name: 'blue-sea-anl-fuse-block-small-left-port',
        position: {
            left: 10,
            top: -4
        }
    }, {
        name: 'blue-sea-anl-fuse-block-small-right-port',
        height: 14,
        width: 8,
        position: {
            right: 9,
            top: -4
        }
    }])
},];