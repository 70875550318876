import * as React from 'react'
import {gql} from "apollo-boost";
import {useQuery} from "@apollo/react-hooks";
import {MenuItem, MenuList} from "@material-ui/core";
import {Diagram} from "../../../../server/src/models/DiagramModel";
import {LoaderContainer} from "../../LoaderContainer";

interface DiagramsListProps {
    onChange: (name: string) => void
}

export const DiagramsList: React.FC<DiagramsListProps> = ({onChange}) => {
    const {data, loading} = useQuery(DIAGRAMS, {fetchPolicy: 'cache-and-network'});
    const diagrams = data?.me?.diagrams || [];

    return (
        <LoaderContainer loading={loading}>
        <MenuList>
            {diagrams.map((diagram: Diagram, idx: number) => (
                <MenuItem key={diagram.name} onClick={() => onChange(diagram.name)}>{diagram.name}</MenuItem>
            ))}
        </MenuList>
        </LoaderContainer>
    )
};

const DIAGRAMS = gql`
    {
        me {
            username
            diagrams {
                name
                description
            }
        }
    }
`;