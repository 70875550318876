import {ProductDefinition} from "../../productList";
import blueSeaSwitch from "./blue-sea-switch.png";
import {AllAroundPortFactory} from "../../portFactories/allAroundPortFactory";
import blueSeaHdBatterySwitch from './blue-sea-hd-battery-switch.png'
import {ProductType} from "../../ProductType";

export const switchProductDefinitions: ProductDefinition[] = [{
    id: 'm-series-300a',
    type: ProductType.SWITCH,
    brand: 'Blue Sea',
    category: 'Switches',
    name: 'm-Series On/Off (300a)',
    description: 'Blue Sea Systems 300 Amp m-Series Battery Switches',
    modelNo: '',
    icon: blueSeaSwitch,
    portFactory: AllAroundPortFactory,
    amazon: {
        textLink: 'https://www.amazon.com/dp/B00445KFZ2/ref=as_li_ss_tl?aaxitk=VI9O.l3ODsWuXswQkd9kaw&pd_rd_i=B00445KFZ2&pf_rd_p=591760d1-6468-480f-9b10-0ee9c85706fd&hsa_cr_id=1206008650601&sb-ci-n=asinImage&sb-ci-v=https://m.media-amazon.com/images/I/81Mt5Hrcf3L.jpg&sb-ci-a=B00445KFZ2&linkCode=ll1&tag=twoblokes-20&linkId=7c18849caf52021eaacd533c38b80b45&language=en_US',
        textAndImage: '<iframe style="width:250px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=twoblokes-20&language=en_US&marketplace=amazon&region=US&placement=B00445KFZ2&asins=B00445KFZ2&linkId=9bad0b7366a0ae30fe55c4c50abc19a4&show_border=true&link_opens_in_new_window=true"></iframe>'
    },
    specs: {
        terminalType: '3/8'

    }
},{
    id: 'blue-sea-hd-battery-switch',
    type: ProductType.SWITCH,
    brand: 'Blue Sea',
    category: 'Switches',
    name: 'Heavy duty switch',
    description: 'Blue Sea Systems HD-Series Heavy Duty Battery Switch',
    icon: blueSeaHdBatterySwitch,
    portFactory: AllAroundPortFactory,
    modelNo: '',
    amazon: {
        textLink: 'https://www.amazon.com/dp/B000K2NYEC/ref=as_li_ss_tl?aaxitk=.BnhIxiFcsFGhoefb-Cbxw&pd_rd_i=B000K2NYEC&pf_rd_p=591760d1-6468-480f-9b10-0ee9c85706fd&hsa_cr_id=1206008650601&sb-ci-n=asinImage&sb-ci-v=https://m.media-amazon.com/images/I/81mCGP65LjL.jpg&sb-ci-a=B000K2NYEC&linkCode=ll1&tag=twoblokes-20&linkId=0ab677e79023cf8152fb3e56b84623e4&language=en_US',
        textAndImage: ''
    }
}];