import {DrawingLayer} from "./DrawingLayer";
import {DrawingContainer} from "./DrawingContainer";
import {DrawingCanvas} from "./DrawingCanvas";
import * as React from "react";
import {ProductNodeModel} from "../model/ProductNodeModel";
import {engine} from "../model/MyEngine";
import { Point } from '@projectstorm/geometry';
import {getProductList} from "../data/productList";

const onDrop = (event: any) => {
    const data = getProductList()[event.dataTransfer.getData('id')];
    const node = new ProductNodeModel(data);
    const point = engine.getRelativeMousePoint(event);
    //TODO: Can do a better job here of making sure that the item is placed on the grid where they drop it
    // Right now just making it the center which is where most people will probably grab them
    node.setPosition(new Point(point.x - (node.product.icon?.width || 0)/2, point.y - (node.product.icon?.height || 0)/2));
    engine.getModel().clearSelection();
    engine.getModel().addNode(node);
    node.setSelected(true);
};


export const DrawingArea: React.FC = () => (
    <DrawingLayer
        onDragOver={(event: MouseEvent) => event.preventDefault()}
        onDrop={(event: MouseEvent) => onDrop(event)}
    >
        <DrawingContainer background="rgb(60, 60, 60)" color="rgba(255,255,255, 0.05)">
            <DrawingCanvas engine={engine}/>
        </DrawingContainer>

    </DrawingLayer>
);
