import lugImage from './lug.png'
import {flatten} from 'lodash'
import {wireSizeOptions} from "../../../wireSizeCalculator/ampacityTables";
import {TerminalTypes} from "../../../TerminalTypes";
import {ProductType} from "../../ProductType";
import {NullPortFactory} from "../../portFactories/NullPortFactory";

export const lugProductDefinitions = flatten(wireSizeOptions.map((size) =>
    TerminalTypes.map(type => ({
        id: `lug-${size}-${type}`,
        type: ProductType.LUG,
        category: '',
        name: `#${size}-${type} lug`,
        description: `#${size}-${type} lug`,
        icon: lugImage,
        portFactory: NullPortFactory,
        brand: '',
        modelNo: ''
    }))
));