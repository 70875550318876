import {LinkModel, PortModel} from '@projectstorm/react-diagrams';
import {ProductPort} from "../data/productList";
import {AbstractModelFactory, DeserializeEvent} from '@projectstorm/react-canvas-core';
import {ProductLinkModel} from "./ProductLinkModel";
import {ProductNodeModel} from "./ProductNodeModel";
import {Rectangle} from '@projectstorm/geometry'

export class ProductPortModel extends PortModel {
    relativePosition: Object;
    height: number;
    width: number;

    constructor(port: ProductPort) {
        super({
            type: 'product-port',
            name: port.name,
            alignment: port.alignment
        });
        this.relativePosition = port.position;
        this.height = port.height;
        this.width = port.width;
        this.options.maximumLinks = 5;
    }


    updateCoords(coords: Rectangle) {
        // SAB - Added isSideways to handle product rotation so that ports stay set correctly
        const isSideways = this.getParent().rotation === 90 || this.getParent().rotation === 270;
        this.width = isSideways ? coords.getHeight() : coords.getWidth();
        this.height = isSideways ? coords.getWidth() : coords.getHeight();
        this.setPosition(coords.getTopLeft());
        this.reportedPosition = true;
        this.reportPosition();
    }


    createLinkModel(factory?: AbstractModelFactory<ProductLinkModel>) {
        const links = Object.values(this.getLinks());
        const selectedLink = links.find(link => link.isSelected());
        if(selectedLink) {
            return selectedLink;
        }
        let color: string = 'red';
        this.getName() === 'negative' && (color = 'yellow');
        this.getParent().product.isPassthroughDevice() && this.getParent().getLinks().length && (color = this.getParent().getLinks()[0].getColor() || 'red');
        return new ProductLinkModel({color});
    }

    getParent(): ProductNodeModel {
        return super.getParent() as ProductNodeModel
    }

    serialize() {
        return {
            ...super.serialize(),
            relativePosition: this.relativePosition,
            height: this.height,
            width: this.width
        }
    }

    deserialize(event: DeserializeEvent<this>) {
        super.deserialize(event);
        this.relativePosition = event.data.relativePosition;
        this.height = event.data.height;
        this.width = event.data.width;
    }

    addLink(link: LinkModel) {
        // SAB -  If I am the source and target port - delete the link
        link.getSourcePort() === this && link.getTargetPort() === this ? setTimeout(() => link.remove()) : super.addLink(link);
    }
}
