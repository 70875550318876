import {registerChecker} from "../checkers";
import {DesignWarning, findPowerSources} from "../designChecker";
import {ProductNodeModel} from "../../ProductNodeModel";
import React from 'react'
import {ProductType} from "../../../data/ProductType";
import {engine} from "../../MyEngine";

registerChecker({
    run: (model) => {
        const flaws: DesignWarning[] = [];
        findPowerSources(model).forEach(powerSource => {
            const linkedProducts = powerSource.getLinkedProductsByPort();

            ['positive', 'negative'].forEach(pole => {
                if (linkedProducts[pole].filter(product => product.product.type !== ProductType.POWER_SOURCE).length > 1) {
                    flaws.push(new DesignWarning(widget(powerSource, pole)))
                }
            })
        });
        return flaws;
    }
});

const widget = (powerSource: ProductNodeModel, pole: string) => () => (
    <div onClick={() => {
        powerSource.setSelected(true);
        engine.repaintCanvas();
    }}>
        <div>Multiple connections to {powerSource.product.name} ({pole === 'positive' ? '+' : '-'})</div>
        <div>Power sources should have only one connection per pole</div>
    </div>
);

