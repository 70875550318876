import * as React from 'react'
import {DesignError, DesignFlaw, DesignWarning} from "../model/designChecker/designChecker";
import {Error as ErrorIcon, Warning as WarningIcon} from "@material-ui/icons";
import {List, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";

export const DesignFlawsPanel = ({flaws}: { flaws: DesignFlaw[] }) => {
    return (
        <List style={{maxWidth: 350}}>
            {flaws.length === 0 && <NoFlawsDetected/>}
            {flaws.map((flaw, idx) => <MyListItem key={idx} flaw={flaw}/>)}
        </List>
    )
};

const NoFlawsDetected = () => (
    <ListItem><ListItemText>No design flaws detected</ListItemText></ListItem>
);

const MyListItem = ({flaw}: { flaw: DesignFlaw }) => (
    <ListItem>
        <ListItemIcon>
                    <span>
                        {flaw instanceof DesignError && <ErrorIcon/>}
                        {flaw instanceof DesignWarning && <WarningIcon/>}
                    </span>
        </ListItemIcon>
        <ListItemText>
            {flaw.widget && flaw.widget()}
        </ListItemText>
    </ListItem>
);

