import React from 'react'
import {SelectField} from "../../../overloaded/form/SelectField";
import {TerminalTypes} from "../../../TerminalTypes";
import {Product} from "../../../model/Product";

interface GenericBatterySpecWidgetProps {
    product: Product
}

export const GenericBatterySpecWidget: React.FC<GenericBatterySpecWidgetProps> = ({product}) => (
    <SelectField fullWidth defaultValue={product.specs.terminalType} options={TerminalTypes} label="Terminal type" onChange={ev => product.specs.terminalType=ev.target.value}/>
);