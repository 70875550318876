import {LinkProperties} from "../model/ProductLinkModel";
import {lookupWireGaugeForVoltageLoss} from "./voltageLosssTables";
import {lookupWireGaugeForAmpacity, wireSizeOptions} from "./ampacityTables";
import {findIndex} from 'lodash'

export const calculateWireSize: (linkProperties: LinkProperties) => string = (linkProperties) => {
    let sizeForAmpacity = lookupWireGaugeForAmpacity(linkProperties);
    sizeForAmpacity === undefined  && (sizeForAmpacity = wireSizeOptions.length - 1);
    let sizeForVoltLoss = lookupWireGaugeForVoltageLoss(linkProperties);

        sizeForVoltLoss === undefined && (sizeForVoltLoss = calculateVoltLoss(linkProperties));
    return wireSizeOptions[Math.max(sizeForAmpacity, sizeForVoltLoss)];
};

const calculateVoltLoss: (linkProperties: LinkProperties) => number = (linkProperties) => {
    const K = 10.75 // constant representing resistance of copper
    const cm = (K * (linkProperties as any).amps  * (linkProperties as any).circuitLength) / (linkProperties.volts * (linkProperties.voltLoss / 100));
    const wireSize =  findIndex(wireCMTable, it => it > cm);
    return wireSize === -1 ? wireSizeOptions.length - 1 : wireSize;
};

const wireCMTable = [1620, 2580, 4110, 6530, 10380, 16510, 26240, 41740, 66360, 83690, 105600, 133100, 167800, 211600]


