import * as React from 'react'
import {DefaultLinkSegmentWidget} from '@projectstorm/react-diagrams'

export class LinkSegmentWidget extends DefaultLinkSegmentWidget {
    render() {
        const Bottom = React.cloneElement(
            this.props.factory.generateLinkSegment(
                this.props.link,
                this.props.selected || this.props.link.isSelected(),
                this.props.path
            ),
            {
                ref: this.props.forwardRef
            }
        );

        const Top = React.cloneElement(Bottom, {
            strokeLinecap: 'round',
            onMouseLeave: () => {
                this.props.onSelection(false);
            },
            onMouseEnter: () => {
                this.props.onSelection(true);
            },
            ...this.props.extras,
            ref: null,
            'data-linkid': this.props.link.getID(),
            strokeOpacity: this.props.selected ? 0.1 : 0,
            strokeWidth: 20,
            fill: 'none'
        });

        return (
            <g>
                {Bottom}
                {Top}
            </g>
        );
    }

}