import {ProductLinkModel} from "./model/ProductLinkModel";

export enum WireSizeComparison{
    SMALLER = 'smaller',
    EQUAL = 'equal',
    LARGER = 'larger'
}

export const compareWireSize = (size1: string) => ({
    to: (size2: string) => {
        const idx1 = ProductLinkModel.getWireSizeIdx(size1);
        const idx2 = ProductLinkModel.getWireSizeIdx(size2);
        if(idx1 < idx2) {
            return WireSizeComparison.SMALLER
        }
        if(idx1 === idx2) {
            return WireSizeComparison.EQUAL
        }
        if(idx1 > idx2) {
            return WireSizeComparison.LARGER
        }
    }
});