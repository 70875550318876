import * as React from 'react';
import {useEffect, useState} from 'react';
import {PropertiesPanel} from "./propertiesPanel/PropertiesPanel";
import {ProductOrderPanel} from "./productOrder/ProductOrderPanel";
import {engine} from "../model/MyEngine";
import {Paper} from "@material-ui/core";
import {InstructionsPanel} from "./instructionsPanel/InstructionsPanel";
import {DesignFlawsPanel} from "./DesignFlawsPanel";
import {HelpPanel} from "./HelpPanel";
import {AuthPanel} from "./auth/AuthPanel";
import {SaveDiagramPanel} from "./diagram/SaveDiagramPanel";
import {LoadDiagramPanel} from "./diagram/LoadDiagramPanel";

export enum SidebarViews {
    PROPERTIES_PANEL = 'propertiesPanel',
    HELP_PANEL = 'helpPanel',
    PRODUCT_ORDER_PANEL = 'productOrderPanel',
    DESIGN_FLAWS_PANEL = 'designFlawsPanel',
    AUTH_PANEL = 'authPanel',
    SAVE_DIAGRAM_PANEL = 'saveDiagramPanel',
    LOAD_DIAGRAM_PANEL = 'loadDiagramPanel'

}

export const Sidebar: React.FC = () => {
    const [designFlaws, setDesignFlaws] = useState(null);
    const [node, setNode] = useState(null);
    const [sidebarView, setSidebarView] = useState(SidebarViews.PRODUCT_ORDER_PANEL);

    useEffect(() => {
        const listener = engine.registerListener({
            modelChanged: () => setTimeout(() => {
                setNode(null);
                setDesignFlaws(null);
                setSidebarView(SidebarViews.PRODUCT_ORDER_PANEL);
            }),
            showHelp: () => {
                setSidebarView(SidebarViews.HELP_PANEL);
            },
            loginButtonPressed: () => {
                setSidebarView(SidebarViews.AUTH_PANEL);
            },
            loginSuccessful: () => {
                setSidebarView(SidebarViews.PRODUCT_ORDER_PANEL);
            },
            saveButtonPressed: () => {
                setSidebarView(SidebarViews.SAVE_DIAGRAM_PANEL)
            },
            loadButtonPressed: () => {
                setSidebarView(SidebarViews.LOAD_DIAGRAM_PANEL)
            },
            diagramSaved: () => {
                setSidebarView(SidebarViews.PRODUCT_ORDER_PANEL);
            }

        }) ;
        return () => listener.deregister();
    });

    useEffect(() => {
        const listener = engine.getModel().registerListener({
            selectionChanged: ({isSelected, node}: any) => {
                if(isSelected) {
                    setNode(node);
                    setSidebarView(SidebarViews.PROPERTIES_PANEL);
                } else {
                    setSidebarView(SidebarViews.PRODUCT_ORDER_PANEL);
                }
            },
            nodeRemoved: ({node: n}: any) => {
                n === node && setNode(null);
                setSidebarView(SidebarViews.PRODUCT_ORDER_PANEL)
            },
            linkRemoved: ({link: l}: any) => {
                l === node && setNode(null);
                setSidebarView(SidebarViews.PRODUCT_ORDER_PANEL)
            },
            designFlawsUpdated: ({flaws}: any) => {
                setDesignFlaws(flaws);
                setSidebarView(SidebarViews.DESIGN_FLAWS_PANEL);
            }
        });
        return () => listener.deregister();
    });



    return (
        <Paper style={{height: '100%', borderRadius: 0, overflow: 'hidden'}}>
            {sidebarView === SidebarViews.PROPERTIES_PANEL && <PropertiesPanel node={node as any}/>}
            {sidebarView === SidebarViews.PRODUCT_ORDER_PANEL && (engine.getModel().getNodes().length ? <ProductOrderPanel/> : <InstructionsPanel/>)}
            {sidebarView === SidebarViews.HELP_PANEL && <HelpPanel/>}
            {sidebarView === SidebarViews.DESIGN_FLAWS_PANEL && <DesignFlawsPanel flaws={designFlaws || []}/>}
            {sidebarView === SidebarViews.AUTH_PANEL && <AuthPanel />}
            {sidebarView === SidebarViews.SAVE_DIAGRAM_PANEL && <SaveDiagramPanel/>}
            {sidebarView === SidebarViews.LOAD_DIAGRAM_PANEL && <LoadDiagramPanel/>}
        </Paper>
    )
};