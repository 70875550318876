import * as React from 'react'
import {ChangeEvent, useState} from 'react'
import {engine} from "../../model/MyEngine";
import {version} from "../../version";
import {gql} from "apollo-boost";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {LoaderContainer} from "../../LoaderContainer";
import {LoginButton} from "../../header/LoginButton";
import {DiagramsList} from "./DiagramsList";
import {TextField} from "../../overloaded/form/TextField";
import {Button} from "../../overloaded/Button";

export const SaveDiagramPanel: React.FC = () => {
    const {data: meData, loading: meLoading} = useQuery(ME_QUERY);


    return (
        <LoaderContainer loading={meLoading}>
            {meData?.me?.username ? <ShowSaveDiagram/> : <DoLoginInstead/>}
        </LoaderContainer>
    )
};

const DoLoginInstead: React.FC = () => (
    <div style={{width: 200}}>
        <p>Saving a diagram requires you to be logged in</p>
        <LoginButton/>
    </div>
);

const ShowSaveDiagram: React.FC = () => {
    const [name, setName] = useState('');
    const [storeDiagramMutation, {loading}] = useMutation(STORE_DIAGRAM);


    const storeDiagram = (): void => {
        storeDiagramMutation({variables: {name, description: '', data: JSON.stringify({version, model: engine.getModel().serialize()})}})
            .then(() => engine.fireEvent({}, 'diagramSaved'))
    };

    const changeNameHandler = (ev: ChangeEvent<HTMLInputElement>) => setName(ev.target.value);

    return (
        <LoaderContainer loading={loading}>
            <div style={{padding: 10}} >
                <p><label>Choose an existing diagram name:</label></p>
                <div style={{border: '1px solid #ddd', height: 150, overflow: 'auto'}}><DiagramsList onChange={setName}/></div>
                <p><label>Or create a new one</label></p>
                <TextField autoFocus value={name} onKeyUp={ev => ev.which === 13 && storeDiagram()} onChange={changeNameHandler} fullWidth/>
                <p>
                    <Button onClick={storeDiagram}>Save</Button>
                </p>
            </div>
        </LoaderContainer>
    )
};

const ME_QUERY = gql`
    {
        me {
            username
        }
    }
`;

const STORE_DIAGRAM = gql`
    mutation StoreDiagram($name: String!, $description: String!, $data: String!)
    {
        me {
            storeDiagram(name: $name, description: $description, data: $data)
        }
    }
`;