import * as React from "react";
import {engine} from "../model/MyEngine";
import {Button} from "../overloaded/Button";

const loadHandler = () => engine.fireEvent({}, 'loadButtonPressed');

export const LoadButton = () => (
    <Button variant="contained" color="primary" size="small" onClick={loadHandler}>
        Load
    </Button>
);
