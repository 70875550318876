import React from 'react'
import {ProductType} from "../data/ProductType";
import {Amazon, ProductIcon} from "../data/productList";


interface ProductInit {
    id: string
    type: ProductType
    brand: string
    category: string
    name: string
    description: string
    icon: ProductIcon
    modelNo: string
    amazon?: Amazon,
    specs: {
        ampDraw: number
        minWireGauge: string
        terminalType: string
    },
    specWidget?: React.FC<any>
}

export class Product  {
    id: string
    type: ProductType
    brand: string
    category: string
    name: string
    description: string
    icon: ProductIcon
    modelNo: string
    amazon?: Amazon
    specs: {
        ampDraw: number
        minWireGauge: string,
        terminalType: string
    };
    specWidget?: React.FC<any>;

    constructor(init: ProductInit) {
        this.id = init.id;
        this.type = init.type;
        this.brand = init.brand;
        this.category = init.category;
        this.name = init.name;
        this.description = init.description;
        this.icon = init.icon;
        this.modelNo = init.modelNo;
        this.amazon = init.amazon;
        this.specs = {
            ampDraw: init.specs.ampDraw,
            minWireGauge: init.specs.minWireGauge,
            terminalType: init.specs.terminalType
        };
        this.specWidget = init.specWidget
    }

    isPassthroughDevice(): boolean {
        return [ProductType.BREAKER, ProductType.FUSE, ProductType.SWITCH, ProductType.BUS, ProductType.SHUNT].includes(this.type)
    }

}