import * as React from "react";
import {engine} from "../model/MyEngine";
import {Button} from "../overloaded/Button";

const saveHandler = () => engine.fireEvent({}, 'saveButtonPressed');

export const SaveButton = () => (
    <Button variant="contained" color="primary" size="small" onClick={saveHandler}>
        Save
    </Button>
);
