import {ProductNodeWidget} from '../ProductNode/ProductNodeWidget';
import {ProductNodeModel} from './ProductNodeModel';
import * as React from 'react';
import {AbstractReactFactory} from '@projectstorm/react-canvas-core';
import {DiagramEngine} from '@projectstorm/react-diagrams-core';
import {Product} from "./Product";

export class ProductNodeFactory extends AbstractReactFactory<ProductNodeModel, DiagramEngine> {
    constructor() {
        super('product');
    }

    generateReactWidget(event: any): JSX.Element {
        return <ProductNodeWidget engine={this.engine} node={event.model} />;
    }

    generateModel(event: any) {
        const node = new ProductNodeModel({} as Product);
            node.deserialize(event.initialConfig);
        return node;
    }
}
