import {ProductDefinition} from "../../productList";
import shunt500a from "./shunt-500a.png";
import {PredefinedPortFactory} from "../../portFactories/PredefinedPortFactory";
import {ProductType} from "../../ProductType";

export const shuntProductDefinitions: ProductDefinition[] = [{
    id: 'shunt-500a',
    type: ProductType.SHUNT,
    brand: 'Deltec',
    category: 'Shunts',
    name: '500 amp shunt',
    description: 'Deltec 500 amp shunt (50mv)',
    modelNo: '',
    icon: shunt500a,
    amazon: {
        textLink: 'https://www.amazon.com/AMMETER-SHUNT-500-AMP-MILLIVOLT/dp/B005BHPG6K/ref=as_li_ss_tl?keywords=shunt&qid=1576803633&sr=8-1&linkCode=ll1&tag=twoblokes-20&linkId=78200bc5afd2a39a0d161cf63aa9e7fa&language=en_US',
        textAndImage: ''
    },
    specs: {
        terminalType: '3/8'
    },
    portFactory: PredefinedPortFactory([1, 2].map((n, idx) => ({
        height: 12,
        width: 8,
        name: `blue-sea-common-busbar-port${idx}`,
        position: {
            left: idx * 20 + 10,
            top: -4
        }
    })))
},];