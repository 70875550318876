import {default as React, ReactElement} from "react";
import {CircularProgress} from "@material-ui/core";

interface LoaderContainerProps {
    children: ReactElement[] | ReactElement,
    loading: boolean
}

export const LoaderContainer: React.FC<LoaderContainerProps> = ({loading, children}) => {
    return (
        <div style={{position: 'relative'}}>
            {loading && (
                <div style={{position: 'absolute', display: 'table-cell', verticalAlign: 'middle', height: '100%', width: '100%', zIndex: 10, backgroundColor: 'rgb(0,0,0,.2)'}}>
                    <div style={{position: 'absolute', left: '50%', transform: 'translate(-50%, -50%)', top: '50%'}}>
                        <CircularProgress variant="indeterminate"/>
                    </div>
                </div>
            )}
            {children}
        </div>
    )
};