import {MyDiagramModel} from "../MyDiagramModel";
import {BaseEvent} from '@projectstorm/react-canvas-core'
import {ProductType} from "../../data/ProductType";
import {ReactElement} from "react";


import './checks/checkForPowerSource'
import './checks/checkLinksHaveLength'
import './checks/checkLinksHaveGauge'
import './checks/checkMultipleLinkToBatteryPole'
import './checks/checkFuseNearBatteries'
import './checks/checkWireSizeIntoEndDevices'

import {checkers} from "./checkers";


export interface DesignChecker {
    run: (model: MyDiagramModel) => DesignFlaw[]
}


export const checkDesign: (model: MyDiagramModel) => DesignFlaw[] = (model) => {

    const flaws: DesignFlaw[] =  checkers.reduce((results: DesignFlaw[], checker) => {
        return results.concat(checker.run(model));
    }, []);
    model.fireEvent({flaws}, 'designFlawsUpdated');
    return flaws;
};

export const findPowerSources = (model: MyDiagramModel) => {
    return model.getNodes().filter(node => node.product.type === ProductType.POWER_SOURCE)
};

export class DesignFlaw {
    widget: () => ReactElement;
    level: string = '';

    constructor(widget: () => ReactElement) {
        this.widget = widget;
    }
}

export class DesignError extends DesignFlaw {
    constructor(widget: () => ReactElement) {
        super(widget);
        this.level = 'error'
    }
}

export class DesignWarning extends DesignFlaw {
    constructor(widget: () => ReactElement) {
        super(widget);
        this.level = 'warning'
    }
}


export interface DesignFlawsUpdatedEvent extends BaseEvent {
    flaws: DesignFlaw[]
}


