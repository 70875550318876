import styled from "@emotion/styled";
import * as React from 'react';
import {LinkPropertiesPanel} from "./LinkPropertiesPanel";
import {ProductLinkModel} from "../../model/ProductLinkModel";
import {ProductNodeProperties} from "./ProductNodeProperties";
import {ProductNodeModel} from "../../model/ProductNodeModel";

const PropertiesPanelWrapper = styled.div`
		background: rgb(110, 110, 110);
		flex-grow: 0;
		flex-shrink: 0;
		color: white;
		padding: 5px;
		min-width: 200px;
		max-width: 300px;
		height: 100%;
		overflow: auto
	`;

interface OptionsInterface {
	type: string
}

interface PropertiesPanelProps {
	node: ProductLinkModel | ProductNodeModel
}

export const PropertiesPanel: React.FC<PropertiesPanelProps> = ({node}) => {
	return (
		<PropertiesPanelWrapper>
			{node && (node.getOptions() as OptionsInterface).type === 'product-link' && <LinkPropertiesPanel node={node as ProductLinkModel} />}
			{node && (node.getOptions() as OptionsInterface).type === 'product' && <ProductNodeProperties node={node as ProductNodeModel} />}
		</PropertiesPanelWrapper>
	)
};