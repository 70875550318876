import * as React from 'react'
import {MouseEventHandler, useEffect} from 'react'
import {Button} from "../overloaded/Button";
import {useQuery} from "@apollo/react-hooks";
import {gql} from 'apollo-boost'
import {engine} from "../model/MyEngine";

export const LoginButton = () => {
    const {loading, error, data, refetch} = useQuery(ME_QUERY);

    useEffect(() => {
        const listener = engine.registerListener({
            loginSuccessful: () => refetch()
        });
        return () => listener.deregister();
    });

    const isLoginButton = ():boolean => !data?.me?.username;

    const handleClick: MouseEventHandler = (ev): void => {
        if(isLoginButton()) {
            engine.fireEvent({}, 'loginButtonPressed');
        } else {
            localStorage.removeItem('token');
            refetch();
        }
    };

    return (
        <Button disabled={loading || error} onClick={handleClick}>
            {isLoginButton() ? 'Sign-in' : 'Logout'}
        </Button>
    )
};

const ME_QUERY = gql`{me{username}}`;