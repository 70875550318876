import * as React from 'react';
import {Tray} from './tray/Tray'
import {Header} from "./header/Header";
import {Body} from "./body/Body";
import {Content} from "./content/Content";
import {DrawingArea} from "./drawingArea/DrawingArea";
import {createMuiTheme, ThemeProvider} from "@material-ui/core";
import {Sidebar} from "./sidebar/Sidebar";
import {GenerateProductListWaiter} from "./GenerateProductListWaiter";
import {GlobalErrorBoundry} from "./GlobalErrorBoundry";
import {ApolloProvider} from '@apollo/react-hooks';
import {client} from "./client";

const theme = createMuiTheme({
    palette: {
        type: 'dark',
        primary: {
            main: '#fff'
        }
    },
});


export const App: React.FC = () => (
    <GlobalErrorBoundry>
        <GenerateProductListWaiter>
            <Body>
                <ThemeProvider theme={theme}>
                    <ApolloProvider client={client}>
                        <Header/>
                        <Content>
                            <Tray/>
                            <DrawingArea/>
                            <Sidebar/>
                        </Content>
                    </ApolloProvider>
                </ThemeProvider>
            </Body>
        </GenerateProductListWaiter>
    </GlobalErrorBoundry>

);













