import React from 'react'
import Ticker from "react-ticker";


const messages = [
    'Welcome to circuit builder',
    'You can zoom the digram in or out by using the mouse wheel or touch pad',
    'Circuit-builder was created by a cruiser for boaters, please donate to help me improve it',
    'If you wish to see a product in the list, please contact me and I will add it',
    'Use the "Recommend wire size" button to set the recommended wire size',
    'You can move multiple items in the diagram by holding the "shift" key while selecting multiple items',
    'The recommended wire size is based on the information that you enter into the form.  "Circuit length" is the distance from the electrical source to the appliance and back to the source',
    'Purchasing products through the Amazon links in the app helps fund the development of circuit-builder',
    'Please contact me with any questions or comments',
    'Hold down "shift" while dragging to select multiple items'
];

export const HelpTicker = () => (
    <Ticker mode="await" offset={500} >
        {({index}) => messages[index % messages.length]}
    </Ticker>
);