import {LinkProperties} from "../model/ProductLinkModel";

export const lookupAmpacityTable = (data: LinkProperties) =>
    (ampacityTables as any)[data.bundleSize][data.tempRating][data.inEngineRoom ? 'inside': 'outside'] as [];

export const lookupWireGaugeForAmpacity = (data: LinkProperties) => {
    const table = lookupAmpacityTable(data);
    return table.findIndex((amps => (data as any).amps <= amps));
};

/*********************
 * Table name format:
 * [bundleSize]:[tempRating]:[inside/outside engine room]
 */



type InsideOutsideIndex = {[key in 'outside' | 'inside']: number[]};
type TemperaturesOptionsType = 75 | 80 | 90 | 105;
type TemperaturesIndex = {[key in  TemperaturesOptionsType]: InsideOutsideIndex}
type BundleSizeOptionsType = '1' | '2-3' | '4-6' | '7-24' | '25+';
type BundleSizesIndex = {[key in BundleSizeOptionsType]: TemperaturesIndex};


export const bundleSizeOptions: BundleSizeOptionsType[]  = ['1', '2-3', '4-6', '7-24', '25+'];
export const temperatureOptions: number[] = [75, 80, 90, 105];
export const wireSizeOptions = ['18','16','14','12','10','8','6','4','3','2','1','1/0','2/0','3/0','4/0'];

const ampacityTables: BundleSizesIndex = {

    '1': {
        75: {
            outside: [10, 15, 20, 25, 40, 65, 95, 125, 145, 170, 195, 230, 265, 310, 360],
            inside: [7.5, 11.3, 15, 18.8, 30, 48.8, 71.3, 93.8, 108.8, 127.5, 146.3, 172.5, 198.8, 232.5, 270]
        },
        80: {
            outside: [15, 20, 25, 35, 50, 70, 100, 130, 150, 175, 210, 245, 285, 330, 385],
            inside: [11.7, 15.6, 19.5, 27.3, 39, 54.6, 78, 101.4, 117.0, 136.5, 163.8, 191.1, 222.3, 257.4, 300.3],
        },
        90: {
            outside: [20, 25, 30, 40, 55, 70, 100, 135, 155, 180, 210, 245, 285, 330, 385],
            inside: [16.4, 20.5, 24.6, 32.8, 45.1, 57.4, 82, 110.7, 127.1, 147.6, 172.2, 200.9, 233.7, 270.6, 315.7],
        },
        105: {
            outside: [20, 25, 35, 45, 60, 80, 120, 160, 180, 210, 245, 285, 330, 385, 445],
            inside: [17, 21.3, 29.8, 38.3, 51, 68, 102, 136, 153, 178, 208.3, 242.3, 280.5, 327.3, 378.3]
        }
    },
    '2-3': {
        75: {
            outside: [7, 10.5, 14, 17.5, 28, 45.5, 66.5, 87.5, 101.5, 119, 136.5, 161, 185.5, 217, 252],
            inside: [5.3, 7.9, 10.5, 13.1, 21, 34.1, 49.9, 65.6, 76.1, 89.3, 102.4, 120.8, 139.1, 162.8, 190]
        },
        80: {
            outside: [10.5, 14, 17.5, 24.5, 35, 49, 70, 91, 105, 122.5, 147, 171.5, 199.5, 231, 269.5],
            inside: [8.2, 10.9, 13.7, 19.1, 27.3, 38.2, 54.6, 71, 81.9, 95.6, 114.7, 133.8, 155.6, 180.2, 210.2]
        },
        90: {
            outside: [14, 17.5, 21, 28, 38.5, 49, 70, 94.5, 108.5, 126, 147, 171.5, 199.5, 231, 269.5],
            inside: [11.5, 14.4, 17.2, 23, 31.6, 40.2, 57.4, 77.5, 89, 103.3, 120.5, 140.6, 163.6, 189.4, 221]
        },
        105: {
            outside: [14, 17.5, 24.5, 31.5, 42, 56, 84, 112, 126, 147, 171.5, 199.5, 231, 269.5, 311.5],
            inside: [11.9, 14.9, 20.8, 26.8, 35.7, 47.6, 71.4, 95.2, 107.1, 125, 145.8, 169.6, 196.4, 229.1, 264.8]
        }
    },
    '4-6': {
        75: {
            outside: [6, 9, 12, 15, 24, 39, 57, 75, 87, 102, 117, 138, 159, 186, 216],
            inside: [4.5, 6.8, 9, 11.3, 18, 29.3, 42.8, 56.3, 65.3, 76.5, 87.8, 103.5, 119.3, 139.5, 162]
        },
        80: {
            outside: [9, 12, 15, 21, 30, 42, 60, 78, 90, 105, 126, 147, 171, 198, 231],
            inside: [7, 9.4, 11.7, 16.4, 23.4, 32.8, 46.8, 60.8, 70.2, 81.9, 98.3, 114.7, 133.4, 154.4, 180.2]
        },
        90: {
            outside: [12, 15, 18, 24, 33, 42, 60, 81, 93, 108, 126, 147, 171, 198, 231],
            inside: [9.8, 12.3, 14.8, 19.7, 27.1, 34.4, 49.2, 66.4, 76.3, 88.6, 103.3, 120.5, 140.2, 162.4, 189.4]
        },
        105: {
            outside: [12, 15, 21, 27, 36, 48, 82, 96, 108, 126, 147, 171, 198, 231, 267],
            inside: [10.2, 12.8, 17.9, 23, 30.6, 40.8, 61.2, 81.6, 91.8, 107.1, 125, 145.4, 168.3, 196.4, 227]
        }
    },
    '7-24': {
        75: {
            outside: [5, 7.5, 10, 12.5, 20, 32.5, 47.5, 62.5, 72.5, 85, 97.5, 115, 132, 155, 180],
            inside: [3.8, 5.6, 7.5, 9.4, 15, 24.4, 35.6, 46.9, 54.4, 63.8, 73.1, 86.3, 99.4, 116.3, 135]
        },
        80: {
            outside: [7.5, 10, 12.5, 17.5, 25.0, 35.0, 50, 65, 75, 87.5, 105, 122.5, 142.5, 165, 192.5],
            inside: [5.9, 7.8, 9.8, 13.7, 19.5, 27.3, 39, 50.7, 58.5, 68.3, 81.9, 95.6, 111.2, 128.7, 150.2]
        },
        90: {
            outside: [10, 12.5, 15, 20, 27.5, 35, 50, 67.5, 77.5, 90, 105, 122.5, 142.5, 165, 192.5],
            inside: [8.2, 10.3, 12.3, 16.4, 22.6, 28.7, 41, 55.4, 63.6, 73.8, 86.1, 100.5, 116.9, 135.3, 157.9]
        },
        105: {
            outside: [10, 12.5, 17.5, 22.5, 30, 40, 60, 80, 90, 105, 122.5, 142.5, 165, 192.5, 222.5],
            inside: [8.5, 10.6, 14.9, 19.1, 25.5, 34, 51, 68, 76.5, 89.3, 104.1, 121.1, 140.3, 163.6, 189.1]
        }
    },
    '25+': {
        75: {
            outside: [4, 6, 8, 10, 16, 26, 38, 50, 58, 68, 78, 92, 106, 124, 144],
            inside: [3, 4.5, 6, 7.5, 12, 19.5, 28.5, 37.5, 43.5, 51, 58.5, 69, 79.5, 93, 108]
        },
        80: {
            outside: [6, 8, 10, 14, 20, 28, 40, 52, 60, 70, 84, 98, 114, 132, 154],
            inside: [4.7, 6.2, 7.8, 10.9, 15.6, 21.8, 31.2, 40.6, 46.8, 54.6, 65.5, 76.4, 88.9, 103, 120.1]
        },
        90: {
            outside: [8, 10, 12, 16, 22, 28, 40, 54, 62, 72, 84, 98, 114, 132, 154],
            inside: [6.6, 8.2, 9.8, 13.1, 18, 23, 32.8, 44.3, 50.8, 59, 68.9, 80.4, 93.5, 108.2, 126.3]
        },
        105: {
            outside: [8, 10, 14, 18, 24, 32, 48, 64, 72, 84, 98, 114, 132, 154, 178],
            inside: [6.8, 8.5, 11.9, 15.3, 20.4, 27.2, 40.8, 54.4, 61.2, 71.4, 83.3, 96.9, 112.2, 130.9, 151.3]
        }
    }
};




