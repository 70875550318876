import {ProductDefinition} from "../../productList";
import blueSeaCommonBus from "./blue-sea-common-bus.png";
import {PredefinedPortFactory} from "../../portFactories/PredefinedPortFactory";
import seaDog5ScrewBus from './sea-dog-5-screw-bus.png'
import blueSeaDualPowerPost from './blue-sea-dual-power-post.png'
import {AllAroundPortFactory} from "../../portFactories/allAroundPortFactory";
import {ProductType} from "../../ProductType";

export const busProductDefinitions: ProductDefinition[] = [{
    id: 'sea-dog-5-screw-bus',
    type: ProductType.BUS,
    brand: 'Sea Dog',
    category: 'Busses',
    name: '5 terminal #8 bus',
    description: '5 terminal #8 bus with #10 studs',
    modelNo: '',
    icon: seaDog5ScrewBus,
    portFactory: AllAroundPortFactory,
    amazon: {
        textLink: 'https://www.amazon.com/Sea-Dog-426710-1-Line-Terminal/dp/B002MBUBTA/ref=as_li_ss_tl?keywords=blue+sea+bus&qid=1577103967&sr=8-8&linkCode=ll1&tag=twoblokes-20&linkId=1d81356dd9155250d4647bf910c49f42&language=en_US',
        textAndImage: ''
    }
},{
    id: 'blue-sea-bus-bar-100-250a',
    type: ProductType.BUS,
    brand: 'Blue Sea',
    category: 'Busses',
    name: 'Bus Bar 100-250a',
    description: 'Blue Sea Systems Common BusBars (100A-250A)',
    modelNo: '',
    icon: blueSeaCommonBus,
    amazon: {
        textLink: 'https://www.amazon.com/Blue-Sea-Systems-2315-Terminal/dp/B000OTJ8A0/ref=as_li_ss_tl?dchild=1&keywords=blue+sea+bus+bar&qid=1576792910&sr=8-2&linkCode=ll1&tag=twoblokes-20&linkId=5ea64eaea7af644baa194c8e2560119b&language=en_US',
        textAndImage: ''
    },
    specs: {
        terminalType: '5/16'

    },
    portFactory: PredefinedPortFactory([1, 2, 3, 4].map((n, idx) => ({
        height: 12,
        width: 8,
        name: `blue-sea-common-busbar-port${idx}`,
        position: {
            left: idx * 15 + 10,
            top: -4
        }
    })))
},{
    id: 'blue-sea-dual-power-post',
    type: ProductType.BUS,
    name: 'Dual PowerPost',
    description: 'Blue Sea Systems Dual PowerPost Cable Connectors',
    amazon: {
        textLink: 'https://www.amazon.com/Blue-Sea-Systems-PowerPost-Studs/dp/B0016HX68E/ref=as_li_ss_tl?dchild=1&keywords=blue+sea+3/8+stud&qid=1577338635&sr=8-3&linkCode=ll1&tag=twoblokes-20&linkId=9cba4c17ca01212a9f6e60cbb8df4d98&language=en_US',
        textAndImage: ''
    },
    icon: blueSeaDualPowerPost,
    brand: 'Blue Sea',
    category: 'Busses',
    modelNo: '',
    portFactory: PredefinedPortFactory([1,2].map((n, idx) => ({
        height: 12,
        width: 8,
        name: `blue-sea-dual-power-post-port-${n}`,
        position: {
            left: idx * 21 + 13,
            top: -4
        }
    })))
}];