export enum ProductType {
    unknown= 'unknown',
    POWER_SOURCE = 'power-source',
    FUSE = 'fuse',
    BREAKER = 'breaker',
    SWITCH = 'switch',
    BUS = 'bus',
    CHARGING = 'charging',
    SHUNT = 'shunt',
    WIRE = 'wire',
    INVERTER = 'inverter',
    LUG = 'lug'
}