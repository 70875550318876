import * as React from 'react'
import {useEffect, useState} from 'react'
import {SignupForm} from "./SignupForm";
import {LoginForm} from "./LoginForm";
import {engine} from "../../model/MyEngine";

enum Forms {
    SIGNUP = 'Sign up',
    LOGIN = 'Login'
}

export const AuthPanel: React.FC = () => {
    const [form, setForm] = useState(Forms.SIGNUP);
    useEffect(() => {
        const listener = engine.registerListener({
            signupComplete: () => setForm(Forms.LOGIN)
        });
        return () => listener.deregister()
    });
    return (
        <div style={{padding: 10, width: 300}}>
            <h2>
                {form}
                <span style={{fontSize: '.7em', paddingLeft: 5}}>
                    or
                    <span
                        onClick={() => setForm(form === Forms.SIGNUP ? Forms.LOGIN : Forms.SIGNUP)}
                        style={{textDecoration: 'underline', color: '#69ea29', paddingLeft: 5}}
                    >
                        {form === Forms.SIGNUP ? Forms.LOGIN : Forms.SIGNUP}
                    </span>
                </span>
            </h2>
            {form === Forms.SIGNUP ? <SignupForm/> : <LoginForm/>}
        </div>
    )
};