import {NodeModel, NodeModelGenerics} from '@projectstorm/react-diagrams';
import {ProductPortModel} from './ProductPortModel';
import {getProductList} from "../data/productList";
import {DeserializeEvent} from '@projectstorm/react-canvas-core';
import {cloneDeep, flatten, get, omit} from 'lodash'
import {ProductLinkModel} from "./ProductLinkModel";
import {engine} from "./MyEngine";
import {Product} from "./Product";

export const LabelPositions = {
    above:  'Above',
    right: 'Right',
    left: 'Left',
    below: 'Below',
    inside: 'Inside'
};


export class ProductNodeModel extends NodeModel<NodeModelGenerics> {
    product: Product;
    rotation: number = 0;

    constructor(productData: Product) {
        super({
            type: 'product',
        });
        this.product = cloneDeep(productData || {});
        productData?.icon?.ports?.forEach(port => this.addPort(new ProductPortModel(port)));
    }

    setRotation(deg: number) {
        this.rotation = deg;
        engine.repaintCanvas();
    }


    getLinks(): ProductLinkModel[] {
        return flatten(Object.values(this.getPorts()).map(port => Object.values(port.getLinks()))) as ProductLinkModel[];
    }

    getLinkedProductsByPort() {
        const response: GenericObject<ProductNodeModel[]> = {};
        const ports = Object.values(this.getPorts());
        ports.forEach(port => {
            const results = response[port.getName() || port.getID()] = [] as ProductNodeModel[];
            Object.values(port.getLinks()).forEach(link => {
                const targetProduct = link.getTargetPort().getParent() as ProductNodeModel;
                targetProduct && targetProduct !== this && results.push(targetProduct);
                const sourceProduct = link.getSourcePort().getParent() as ProductNodeModel;
                sourceProduct && sourceProduct !== this && results.push(sourceProduct);
            })
        });
        return response;
    }

    getLinkedProducts() {
        return flatten(Object.values(this.getLinkedProductsByPort()));
    }

    setLabel(label: string) {
        this.product.icon && (this.product.icon.label = label);
        this.getParentCanvasModel().fireEvent({node: this, isCreated: false}, 'nodesUpdated')
    }

    setLabelPosition(labelPosition: string) {
        this.product.icon && (this.product.icon.labelPosition = labelPosition);
        this.getParentCanvasModel().fireEvent({node: this, isCreated: false}, 'nodesUpdated')
    }

    setSelected(selected: boolean = true) {
        super.setSelected(selected);
        this.getParentCanvasModel().fireEvent({isSelected: selected, node: this}, 'selectionChanged');
    }

    serialize() {
        return {rotation: this.rotation, ...super.serialize(), product: omit(this.product,  'icon.src') as Product}
    }

     deserialize(event: DeserializeEvent<this>) {
         event.data && super.deserialize(event);
         event.data && (this.product = new Product({...cloneDeep(get(getProductList(),  event.data.product.id)), ...event.data.product}));
         event.data && (this.rotation = event.data.rotation);
     }

    remove() {
        super.remove();
        this.getParentCanvasModel().fireEvent({node: this}, 'nodeRemoved');
    }
}


interface GenericObject<TValue> {
    [key: string]: TValue;
}
