import * as React from 'react';
import {MouseEventHandler, useEffect, useState} from 'react'
import {ProductNodeModel} from '../model/ProductNodeModel';
import {DiagramEngine} from '@projectstorm/react-diagrams';
import {ProductPortModel} from "../model/ProductPortModel";
import {Port} from "./Port";
import {Product} from "../model/Product";


export const ProductNodeWidget = ({node, engine}: { node: ProductNodeModel, engine: DiagramEngine }) => {
    const [rotation, setRotation] = useState(node.rotation);
    const [portsVisible, setPortsVisible] = useState(false);

    useEffect(() => {
        const l = engine.registerListener({
            repaintCanvas: () => setRotation(node.rotation)
        });
        return () => l.deregister();
    });

    const shouldMakePortsVisible: MouseEventHandler = (ev) => {
        ev.buttons === 1 && setPortsVisible(true)
    };

    return (
        <div onMouseOver={shouldMakePortsVisible} onMouseOut={() => setPortsVisible(false)}
            style={{
                position: 'relative',
                width: node.product?.icon?.width,
                height: node.product?.icon?.height,
                border: node.isSelected() ? '1px solid white' : 'none',
                borderRadius: 5,
                transform: `rotate(${rotation}deg)`
            }}>
            <img onMouseDown={ev => ev.preventDefault()} style={{WebkitUserDrag: 'none', position: 'absolute'} as React.CSSProperties} src={node.product?.icon?.img} height={node.product?.icon?.height} width={node.product?.icon?.width} alt={node.product.name}/>
            <label style={{position: 'absolute', color: '#ddd', textAlign: 'center', display: 'block', ...getLabelPosition(node.product)}}>{node.product?.icon?.label}</label>
            {Object.values(node.getPorts()).map(port => <Port visible={portsVisible} key={port.getName() + rotation} port={port as ProductPortModel}/>)}
        </div>
    );
};

const getLabelPosition = (productData: Product) => {
    const fns = {
        right: () => ({left: productData.icon.width + 5, lineHeight: `${productData.icon.height}px`, whiteSpace: 'nowrap'}),
        left: () => ({right: productData.icon.width + 5, lineHeight: `${productData.icon.height}px`, whiteSpace: 'nowrap'}),
        above: () => ({right: productData.icon.width / 2 - 50, top: -18, width: 100, textAlign: 'center'}),
        below: () => ({right: productData.icon.width / 2 - 50, bottom: -18, width: 100, textAlign: 'center'}),
        inside: () => ({top: 10, width: '100%', color: 'black'})
    } as any;
    return fns[productData.icon.labelPosition]()
};
