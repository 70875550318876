import {ProductDefinition} from "../../productList";
import mastervolt12_2000Icon from "./mastervolt-12-2000-inverter.png";
import {ProductType} from "../../ProductType";
import {PredefinedPortFactory} from "../../portFactories/PredefinedPortFactory";

export const inverterProductDefinitions: ProductDefinition[] = [{
    id: 'mastervolt-12-2000-inverter',
    type: ProductType.INVERTER,
    brand: 'Mastervolt',
    category: 'Inverters',
    name: 'Inverter 12-2000',
    description: 'Mastervolt AC Master 12/2000 (120 V)',
    modelNo: '',
    icon: mastervolt12_2000Icon,
    amazon: {
        textLink: '',
        textAndImage: ''
    },
    specs: {
        ampDraw: 200,
        minWireGauge: '1/0',
        terminalType: '5/16'
    },
    portFactory: PredefinedPortFactory([{
        height: 12,
        width: 8,
        name: `mastervolt-12-2000-inverter-positive`,
        position: {
            left: 10,
            bottom: -6
        }

    }, {
        height: 12,
        width: 8,
        name: `mastervolt-12-2000-inverter-negative`,
        position: {
            right: 10,
            bottom: -6
        }

    }])
}];