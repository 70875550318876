import React, {ChangeEvent, useState} from 'react'
import styled from "@emotion/styled";
import {getProductList} from "../data/productList";
import {TrayItem} from "./TrayItem";
import {concat, get, map, update} from 'lodash'
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Product} from "../model/Product";
import {ProductType} from "../data/ProductType";

const TrayDiv = styled.div`
		min-width: 50px;
		background: rgb(20, 20, 20);
		flex-grow: 0;
		flex-shrink: 0;
		height: 100%;
		overflow: auto;
	`;


export const getProducts = () => Object.values(getProductList());

export const Tray = () => (
    <TrayDiv>
        <CategoryTree tree={organizeProducts()}/>
    </TrayDiv>
);

interface CategoryTreeProps {
    tree: Object
}

const CategoryTree: React.FC<CategoryTreeProps> = ({tree}) => { 
    const [expanded, setExpanded] = useState('');
    
    const handleExpanded = (category: string) => (event: ChangeEvent<{}>, expanded: boolean) => {
        setExpanded(expanded ? category : '')
    };
    
    return (
    <div style={{padding: 5}}>
        {map(tree, (products, category) => (
            <ExpansionPanel key={category} expanded={expanded === category} onChange={handleExpanded(category)}>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>{category}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <ProductList products={products as any}/>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        ))}
    </div>
)
};


interface ProductListProps {
    products: Array<Product>
}

const ProductList: React.FC<ProductListProps> = ({products}) => (
    <div style={{marginBottom: 5, width: '100%'}}>
        {products.map((product, idx) => (
            <div key={product.id}>
                <div key={product.id} style={{marginBottom: 15}}>
                    <TrayItem model={product}/>
                </div>
                {idx < products.length - 1 && (
                    <div style={{marginBottom: 10}}>
                        <hr style={{borderColor: '#888'}}/>
                    </div>
                )}
            </div>
        ))}
    </div>
);

const organizeProducts = () => {
    const tree = {};
    getProducts().forEach(p => {
        if(p.type !== ProductType.WIRE && p.type !== ProductType.LUG) {
            update(tree, `[${p.category}]`, () => concat(get(tree, `[${p.category}]`) || [], [p]));
        }
    });
    return tree;
};


