import {RightAngleLinkModel} from '@projectstorm/react-diagrams';
import {DeserializeEvent} from "@projectstorm/react-canvas-core";
import {engine} from "./MyEngine";
import {PortModel} from '@projectstorm/react-diagrams-core'

export class LinkProperties {
    length: number | undefined;
    circuitLength: number | undefined;
    voltLoss: number = 3;
    amps: number | undefined;
    tempRating: number = 90;
    volts: number = 12;
    wireGauge: string = '';
    bundleSize: string = '1';
    inEngineRoom: boolean = false;
}

interface InitialConfig {
    color?: string
}

export class ProductLinkModel extends RightAngleLinkModel {
    data = new LinkProperties();

    static wireSizes: string[] = [18, 16, 14, 12, 10, 8, 6, 4, 3, 2, 1, '1/0', '2/0', '3/0', '4/0'].map(x => x.toString());
    static getWireSizeIdx = (size: string): number => ProductLinkModel.wireSizes.indexOf(size);

    constructor(initialConfig: InitialConfig = {}) {
        super({
            type: 'product-link'
        });
        this.setColor(initialConfig.color || 'red');
        this.setSelected(false);
        this.getOptions().selectedColor = this.getColor();
        this.registerListener({
            targetPortChanged: () =>  this.setSelected(false),
            colorChanged: (data: any) => {
                this.getOptions().selectedColor = data.color;
                this.getParentCanvasModel().fireEvent({node: this}, 'nodesUpdated')
            }
        });
        engine.getModel().clearSelection();
    }

    serialize() {
        return {...super.serialize(), data: this.data}
    }

    deserialize(event: DeserializeEvent<this>) {
        event.data && super.deserialize(event);
        this.data = event.data.data;
    }

    getColor() {
        return this.options.color
    }

    setSelected(selected: boolean = true) {
        super.setSelected(selected);
        this.getParentCanvasModel()?.fireEvent({isSelected: selected, node: this}, 'selectionChanged');
    }

    remove() {
        super.remove();
        this.getParentCanvasModel().fireEvent({link: this}, 'linkRemoved');
    }

    // SAB override since in their version the addLink was called first before the target port was being set
    // This messes up other code
    setTargetPort(port: PortModel) {
        if (this.targetPort !== null) {
            this.targetPort.removeLink(this);
        }
        this.targetPort = port;
        if (port !== null) {
            port.addLink(this);
        }
        this.fireEvent({ port }, 'targetPortChanged');
    }


}