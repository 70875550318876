import ApolloClient from 'apollo-boost'

export const client = (global as any).client = new ApolloClient({
    uri: window.location.href.includes('localhost') ? 'http://localhost:4000/graphql' : '/graphql',
    fetchOptions: {
        credentials: 'include'
    },
    request: async (operation) => {
        operation.setContext({
            headers: {
                auth: localStorage.getItem('token')
            }
        });
    },
    clientState: {
        defaults: {
        },
        resolvers: {
            Mutation: {
            }
        }
    }
});





