import {ProductLinkModel} from "./ProductLinkModel";
import {RightAngleLinkFactory} from '@projectstorm/react-diagrams-routing'
import {RightAngleLinkModel} from '@projectstorm/react-diagrams'
import {LinkWidget} from "../ProductNode/LinkWidget";
import * as React from 'react'
import {engine} from "./MyEngine";
import {css, keyframes} from "@emotion/core";
import styled from "@emotion/styled";


const S: any = {
    Keyframes: keyframes`
		from {
			stroke-dashoffset: 24;
		}
		to {
			stroke-dashoffset: 0;
		}
	`,

    selected:  () => css`
		stroke-dasharray: 10, 2;
		animation: ${S.Keyframes} 1s linear infinite;
	`,

    Path: styled.path<{ selected: boolean }>`
		${p => p.selected && S.selected()};
		fill: none;
		pointer-events: all;
	`
}

export class ProductLinkFactory extends RightAngleLinkFactory{

    constructor() {
        super();
        this.type = 'product-link'
    }

    generateModel(event: any)  : RightAngleLinkModel {
        return new ProductLinkModel(event?.initialConfig || {});
    }


    generateReactWidget(event: any): JSX.Element {
        return <LinkWidget diagramEngine={engine} link={event.model} factory={this} />;
    }

    generateLinkSegment(link: ProductLinkModel, selected: boolean, path: string) {
        return (
            <S.Path
                selected={selected}
                stroke={selected ? link.getOptions().selectedColor : link.getOptions().color}
                strokeWidth={Math.max((link.data.wireGauge && ProductLinkModel.getWireSizeIdx(link.data.wireGauge) / 3) || 0, 2)}
                style={{opacity: link.data.circuitLength && link.data.wireGauge ? 1 : .5}}
                d={path}
            />
        );
    }
}


