import * as React from 'react'
import Modal from '@material-ui/core/Modal';
import {generateProductList} from "./data/productList";

export const GenerateProductListWaiter = ({children}: {children: any}) => {
    const [productListReady, setProductListReady] = React.useState(false);
    generateProductList().then(() => setProductListReady(true));

    return productListReady ? children : (
        <Modal open={true}>
            <div style={{color: 'white', height: '100%', position: 'relative'}}>
                <h2 style={{position: 'absolute', left: '50%', top: '50%'}}>Preparing product list...</h2>
            </div>
        </Modal>
    )
};