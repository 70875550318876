import createEngine from "@projectstorm/react-diagrams";
import {MyDiagramModel} from "./MyDiagramModel";
import {ProductNodeFactory} from "./ProductNodeFactory";
import {ProductPortFactory} from "./ProductPortFactory";
import {ProductLinkFactory} from "./ProductLinkFactory";
import {DiagramEngine} from '@projectstorm/react-diagrams-core'
import {DefaultDiagramState} from '@projectstorm/react-diagrams'
import {DragNewLinkState} from "./DragNewLinkState";

interface MyEngine extends DiagramEngine {
    getModel(): MyDiagramModel,
    newModel(): void
}

export const engine: MyEngine  = createEngine({
    registerDefaultDeleteItemsAction: false
}) as MyEngine;

// This is mostly here for tests since this is a singleton
engine.newModel = () => {
    engine.setModel(new MyDiagramModel());
};

//engine.getStateMachine().pushState(new DefaultDiagramState());
(engine.getStateMachine().getCurrentState() as DefaultDiagramState).dragNewLink = new DragNewLinkState();

engine.newModel();

engine.getNodeFactories().registerFactory(new ProductNodeFactory());
engine.getPortFactories().registerFactory(new ProductPortFactory());
engine.getLinkFactories().registerFactory(new ProductLinkFactory());


const state = engine.getStateMachine().getCurrentState();
if (state instanceof DefaultDiagramState) {
    state.dragNewLink.config.allowLooseLinks = false;
}

export const repaintCanvas = () => {
    engine.fireEvent({}, 'repaintCanvas');
};


(window as any).e = engine;

