import {DesignError, findPowerSources} from "../designChecker";
import {registerChecker} from "../checkers";
import React from "react";

registerChecker({
    run: (model => {
        // Check if there is a power source
        if (findPowerSources(model).length === 0) {
            return [new DesignError(() => (
                <div>No power source - please add one</div>
            ))];
        }
        return [];
    })
});

