import {ProductDefinition} from "../../productList";
import blueSeaSingleLevelSurfaceMountBreaker from "./blue-sea-single-lever-surface-mount-breaker.png";
import {DiamondPortFactory} from "../../portFactories/diamondPortFactory";
import {ProductType} from "../../ProductType";

export const breakerProductDefinitions: ProductDefinition[] = [{
    id: 'blue-sea-single-lever-surface-mount-breaker',
    type: ProductType.BREAKER,
    brand: 'Blue Sea',
    category: 'Breakers',
    name: 'Breaker 25-200a',
    description: 'Blue Sea Systems 187 Series, 285 Series & Klixon Circuit Breakers (25-200a)',
    modelNo: '',
    icon: blueSeaSingleLevelSurfaceMountBreaker,
    specs: {
        terminalType: '5/16'
    },
    amazon: {
        textLink: 'https://www.amazon.com/Blue-Sea-Systems-Circuit-Breakers/dp/B07CVT3SF6/ref=as_li_ss_tl?_encoding=UTF8&pd_rd_i=B07CVT3SF6&pd_rd_r=7af1a5f3-b42d-4029-bf5d-d17a511df5c0&pd_rd_w=27YnV&pd_rd_wg=XDSRO&pf_rd_p=0e5324e1-c848-4872-bbd5-5be6baedf80e&pf_rd_r=H3HRRA2VX6TZWP7HH1ZY&refRID=H3HRRA2VX6TZWP7HH1ZY&linkCode=ll1&tag=twoblokes-20&linkId=930c50ffb04ee6022616fdc4406a1e1a&language=en_US',
        textAndImage: ''
    },
    portFactory: DiamondPortFactory({})
}];