import {AllAroundPortFactory} from "../../portFactories/allAroundPortFactory";
import {ProductDefinition} from "../../productList";
import redWireSpool from './red-wire-spool.png'
import yellowWireSpool from './yellow-wire-spool.png'
import blackWireSpool from './black-wire-spool.png'
import {flatten} from 'lodash'
import {ProductType} from "../../ProductType";
import {wireSizeOptions} from "../../../wireSizeCalculator/ampacityTables";

const images: any = {red: redWireSpool, yellow: yellowWireSpool, black: blackWireSpool};
export const wireProductDefinitions = flatten(wireSizeOptions.map(gauge =>
    ['red', 'yellow', 'black'].map(color =>
        ({
            id: `anchor-wire-${gauge}-${color}`,
            type: ProductType.WIRE,
            category: 'Wire',
            name: `Wire ${gauge} gauge ${color}`,
            description: `Wire ${gauge} gauge ${color}`,
            icon: images[color],
            portFactory: AllAroundPortFactory,
            brand: '',
            modelNo: '',
            amazon: {
                textLink: 'https://www.amazon.com/Ancor-Marine-Grade-Primary-Battery/dp/B000NV0G0U/ref=as_li_ss_tl?dchild=1&keywords=marine+wire&qid=1576999836&sr=8-6&linkCode=ll1&tag=twoblokes-20&linkId=64fbaf70c27b0555464a0c781b17dfa8&language=en_US',
                textAndImage: ''
            }
        })
    )
)) as ProductDefinition[];





