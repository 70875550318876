import * as React from 'react'
import {ChangeEvent, ChangeEventHandler, MouseEvent, useState} from 'react'
import {ProductLinkModel} from "../../model/ProductLinkModel";
import {Button, CheckboxProps, TextFieldProps} from "@material-ui/core";
import {TextField} from "../../overloaded/form/TextField";
import {SelectField} from "../../overloaded/form/SelectField";
import {CheckboxField} from "../../overloaded/form/CheckboxField";
import {ActionButtons} from "./ActionButtons";
import {useForceRender} from "../../useForceRender";
import {calculateWireSize} from "../../wireSizeCalculator/calculateWireSize";

//const BROWN = '#7b4f2b';

interface LinkPropertiesProps {
    node: ProductLinkModel
}

const updateDataNumber = (ev: ChangeEvent, node: ProductLinkModel, propName: any) => {
    (node.data as any)[propName] = parseInt((ev?.target as HTMLInputElement)?.value, 10);
};

export const LinkPropertiesPanel: React.FC<LinkPropertiesProps> = ({node}) => {
    const setColor = useState(node.getColor())[1];
    const forceRender = useForceRender();
    return (
        <div key={node.getOptions().id}>
            <ActionButtons node={node}/>
            <div style={{marginBottom: 15}}>
                <label>Color:</label>
                {['red', 'yellow', 'black'].map(color => (
                    <ColorBox key={color} color={color} node={node} onClick={(ev: MouseEvent) => {
                        node.setColor(color);
                        setColor(color);
                    }}/>
                ))}
            </div>
            <SelectField fullWidth label="Voltage" defaultValue={node.data.volts} onChange={ev => node.data.volts = parseInt(ev.target.value, 10)} options={voltageOptions}/>
            <PropTextField label="Length (feet)" defaultValue={node.data.length} onChange={ev => {
                updateDataNumber(ev, node, 'length');
                node.data.circuitLength = parseInt(ev.target.value, 10) * 2;
                isNaN(node.data.circuitLength) && (node.data.circuitLength = 0);
                forceRender();
            }}/>
            <PropTextField key={node.data.circuitLength} label="Total Circuit Length (feet)" defaultValue={node.data.circuitLength} onChange={ev => updateDataNumber(ev, node, 'circuitLength')}/>
            <SelectField fullWidth label="Max voltage loss (%)" defaultValue={node.data.voltLoss} onChange={ev => node.data.voltLoss = parseInt(ev.target.value, 10)} options={{'3': '3', '10': '10'}} />
            <PropTextField label="Amperage" defaultValue={node.data.amps} onChange={ev => updateDataNumber(ev, node, 'amps')}/>
            <PropCheckbox label="In engine room?" property="inEngineRoom" node={node}/>
            <SelectField fullWidth label="Bundle size" defaultValue={node.data.bundleSize} onChange={ev => node.data.bundleSize = ev.target.value} options={bundleOptions}/>
            <SelectField fullWidth label="Insulation temp rating" defaultValue={node.data.tempRating || ''} onChange={ev => node.data.tempRating = parseInt(ev.target.value, 10)} options={tempRatingOptions}/>
            <Button style={{marginBottom: 15}} fullWidth variant="contained" onClick={() => recommendWireSize(node, forceRender)}>Recommend wire size</Button>
            <SelectField fullWidth key={node.data.wireGauge} label="Wire gauge" defaultValue={node.data.wireGauge} onChange={setWireSize(node)} options={ProductLinkModel.wireSizes}/>
        </div>
    );
};

const setWireSize = (node: ProductLinkModel): ChangeEventHandler => (ev: ChangeEvent<HTMLInputElement>) => {
    node.data.wireGauge = ev.target.value;
};

const recommendWireSize = (node: ProductLinkModel, forceRender: Function) => {
    node.data.wireGauge = calculateWireSize(node.data);
    forceRender();
};

interface PropCheckboxProps extends CheckboxProps {
    label: string
    property: string
    node: ProductLinkModel
}

const PropCheckbox = ({property, node, ...props}: PropCheckboxProps) => {
    const toggleValue = () => {
        (node.data as any)[property] = (node.data as any)[property] || false;
        (node.data as any)[property] = !(node.data as any)[property];
    };

    return (
        <div>
            <CheckboxField {...props} defaultChecked={!!(node.data as any)[property]} onClick={toggleValue} />
        </div>
    )
};

const PropTextField = (props: TextFieldProps) => {
    return (
        <div style={{marginBottom: 15}}>
            <TextField {...{variant: 'outlined', fullWidth: true, ...props} as TextFieldProps} />
        </div>
    );
};

interface ColorBoxProperties {
    color: string;
    onClick: (event: MouseEvent) => void
    node: ProductLinkModel
}

const ColorBox: React.FC<ColorBoxProperties> = ({color, onClick, node}) => (
    <div
        onClick={onClick}
        style={{
            width: '1em',
            height: '1em',
            display: 'inline-block',
            backgroundColor: color,
            marginLeft: 10,
            border: node.getColor() === color ? '1px solid white' : 'none'
        }}/>
);


const voltageOptions = [12, 24].reduce((memo: any, v: number) => {
    memo[v] = v.toString();
    return memo;
}, {});

const tempRatingOptions = [75,80,90,105].reduce((memo: any, v: number) => {
    memo[v] = v.toString();
    return memo;
}, {});

const bundleOptions = ['1','2-3', '4-6', '7-24', '25+'].reduce((memo: any, v: string) => {
    memo[v] = v;
    return memo;
}, {});

