import {ProductDefinition} from "../../productList";
import blueSeaACR from "./blue-sea-acr.png";
import {AllAroundPortFactory} from "../../portFactories/allAroundPortFactory";
import balmarDuoCharge from './balmar-duo-charge.png';
import {ProductType} from "../../ProductType";

export const chargingProductDefinitions: ProductDefinition[] = [{
    id: 'blue-sea-acr',
    type: ProductType.CHARGING,
    brand: 'Blue Sea',
    category: 'Charging',
    name: 'Automatic Charging Relay',
    description: 'Blue Sea Systems Automatic Charging Relay (ACR)',
    modelNo: '',
    icon: blueSeaACR,
    portFactory: AllAroundPortFactory,
    amazon: {
        textLink: 'https://www.amazon.com/Blue-Sea-Systems-Automatc-Charging/dp/B000OTIPDQ/ref=as_li_ss_tl?dchild=1&keywords=blue+sea+acr&qid=1577422012&sr=8-1&linkCode=ll1&tag=twoblokes-20&linkId=16f69a2b602076d13654d7b5d50ae5f4&language=en_US',
        textAndImage: ''
    }
}, {
        id: 'balmar-duo-charge',
        type: ProductType.CHARGING,
        brand: 'Balmar',
        category: 'Charging',
        name: 'Balmar Digital Duo Charge',
        description: 'Balmar Digital Duo Charge - 12/24V',
        modelNo: '',
        icon: balmarDuoCharge,
        portFactory: AllAroundPortFactory,
        amazon: {
            textLink: 'https://www.amazon.com/Balmar-Digital-Duo-Charge-24V/dp/B000M6F0YI/ref=as_li_ss_tl?dchild=1&keywords=balmar+digital+duo+charge&qid=1577987207&s=sporting-goods&sr=1-1&linkCode=ll1&tag=twoblokes-20&linkId=e9691ecd034affcd4ae61024df99eca5&language=en_US',
            textAndImage: ''
        }
}];