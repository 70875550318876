import * as React from 'react'
import {engine} from "../../model/MyEngine";
import {ProductNodeModel} from "../../model/ProductNodeModel";
import {LinkProperties, ProductLinkModel} from "../../model/ProductLinkModel";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {map} from 'lodash'
import {Amazon, getProductList, wireProductsLookup} from "../../data/productList";
import {AmazonBuyNowBtn} from "../../amazon/AmazonBuyNowBtn";
import {IfDefined} from "../../IfDefined";
import {Product} from "../../model/Product";
import {Checkbox} from "@material-ui/core";
import {IsDemo} from "../../IsDemo";
import {Button} from "../../overloaded/Button";
import {CheckboxField} from "../../overloaded/form/CheckboxField";

interface OrderItemInterface {
    id: string
    name: string
    description: string
    icon: string
    qty: number
}

export const ProductOrderPanel = () => (
    <div style={{display: 'flex', height: '100%', flexDirection: 'column'}}>
        <div style={{flex: 1, overflow: 'auto'}}>
            <Table style={{width: 300}} size="small">
                <TableBody>
                    {generateOrderList().map((item) => (
                        <TableRow key={item.name}>
                            <TableCell style={{paddingRight: 5, paddingLeft: 5}}>
                                <IsDemo>{(isDemo) => (
                                    isDemo ? <Checkbox defaultChecked style={{padding: 0, color: '#c3b3b7'}}/> : null
                                )}</IsDemo>
                            </TableCell>
                            <TableCell style={{paddingLeft: 0, paddingRight: 5}}>
                                <img src={item.icon as string} alt="item to be ordered"/>
                            </TableCell>
                            <TableCell style={{paddingLeft: 0, paddingRight: 0, whiteSpace: 'nowrap'}}>
                                {item.qty} x
                            </TableCell>
                            <TableCell style={{overflow: 'hidden', padding: 5}}>
                                <div>
                                    {item.name}
                                </div>

                                <IsDemo>
                                    {(isDemo) => isDemo ? null : (
                                        <IfDefined value={getProductList()[item.id]?.amazon}>
                                            {(amazon: Amazon) => (
                                                <div style={{textAlign: 'right'}}>
                                                    <div style={{display: 'inline'}}>
                                                        <AmazonBuyNowBtn amazon={amazon}/>
                                                    </div>
                                                </div>
                                            )}
                                        </IfDefined>
                                    )}
                                </IsDemo>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
        <IsDemo>
            {(isDemo) => isDemo ? (
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={4} style={{textAlign: 'right', border: 'none'}}>
                                <CheckboxField label="Make kit"/>
                                <Button variant="contained" style={{backgroundColor: '#ffbf05'}}>Add to cart</Button>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            ) : null}
        </IsDemo>
    </div>
);

const isNotGeneric = (product: Product) => product.brand;

const generateProductOrderList: () => Array<OrderItemInterface> = () => {
    const nodes = engine.getModel().getNodes().map(n => (n as ProductNodeModel).product);
    const orderObj = nodes.filter(isNotGeneric).reduce((obj, product) => {
        obj[product.name] = obj[product.name] || {
            id: product.id,
            name: product.name,
            description: product.description,
            icon: product.icon?.img
        };
        obj[product.name].qty = (obj[product.name].qty || 0) + 1;
        return obj;
    }, {} as any);
    return Object.values(orderObj) as Array<OrderItemInterface>;
};

interface LinksList extends LinkProperties {
    color: string
}

const generateWireOrderList = () => {
    const links = engine.getModel().getLinks().map(n => {
        const data = (n as ProductLinkModel).data;
        const color = (n as ProductLinkModel).getColor();
        return {...data, color} as any;
    });
    const orderData = links.reduce((order, link: LinksList) => {
        if (link.wireGauge && link.length) {
            order[`${link.wireGauge}:${link.color}`] = (order[`${link.wireGauge}:${link.color}`] || 0) + link.length;
        }
        return order;
    }, {} as any);

    const items = map(orderData, (qty, id) => {
        const productId = (wireProductsLookup as any)[id];
        const data = (getProductList() as any)[productId] as Product;
        return {
            id: productId,
            name: data.name,
            description: data.description,
            qty: qty,
            icon: data.icon?.img
        }

    });
    return items;
};

export const generateLugOrderList = () => {
    const lugs: any = {};
    (engine.getModel().getLinks() as ProductLinkModel[]).forEach(l => {
        const nodes: ProductNodeModel[] = [l.getSourcePort().getParent() as ProductNodeModel, l.getTargetPort().getParent() as ProductNodeModel];
        nodes.forEach(n => {
            if (l.data.wireGauge && n.product.specs.terminalType) {
                lugs[`lug-${l.data.wireGauge}-${n.product.specs.terminalType}`] = (lugs[`lug-${l.data.wireGauge}-${n.product.specs.terminalType}`] || 0) + 1
            }
        })
    });
    return Object.keys(lugs).map(id => {
        const data = getProductList()[id];
        return {
            id: data.id,
            name: data.name,
            icon: data.icon.img,
            qty: lugs[id]
        }
    });
};

const generateOrderList = () => [...generateProductOrderList(), ...generateWireOrderList(), ...generateLugOrderList()];



