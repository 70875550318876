import {DesignError, DesignWarning, findPowerSources} from "../designChecker";
import {registerChecker} from "../checkers";
import React from "react";
import {ProductType} from "../../../data/ProductType";
import {ProductNodeModel} from "../../ProductNodeModel";
import {engine} from "../../MyEngine";

registerChecker({
    run: (model => {
        const flaws: DesignError[] = [];

        findPowerSources(model).forEach(powerSource => {
            powerSource.getLinkedProductsByPort().positive.forEach((child) => {
                const type = child.product.type;
                // Check to see if the connected node is not another battery, switch or fuse
                if (type !== undefined && type !== ProductType.POWER_SOURCE && type !== ProductType.SWITCH && type !== ProductType.FUSE && type !== ProductType.BREAKER) {
                    flaws.push(new DesignWarning(widget(powerSource)));
                    return;
                }

                // If connected to a switch, make sure the next device is a fuse
                if (type !== undefined && type === ProductType.SWITCH) {
                    const fuseExists = !!child.getLinkedProducts().filter(n => n.product.type === ProductType.FUSE || n.product.type === ProductType.BREAKER).length;
                    fuseExists || flaws.push(new DesignWarning(widget(powerSource)))
                }
            });

        });
        return flaws;
    })
});

const widget = (powerSource: ProductNodeModel) => () => (
    <div
        onClick={() => {
            powerSource.setSelected(true);
            engine.repaintCanvas();
        }}
    >
        <div>No fuse or breaker near {powerSource.product.name}</div>
        <div>Add a fuse or breaker near {powerSource.product.name}</div>
    </div>
);

