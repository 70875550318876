import {AbstractDisplacementState, AbstractDisplacementStateEvent, Action, InputType} from '@projectstorm/react-canvas-core';

import {DiagramEngine, PortModel} from '@projectstorm/react-diagrams';
import {MouseEvent} from 'react';
import {reverse} from 'lodash'
import {ProductLinkModel} from "./ProductLinkModel";

export interface DragNewLinkStateOptions {
    /**
     * If enabled, the links will stay on the canvas if they dont connect to a port
     * when dragging finishes
     */
    allowLooseLinks?: boolean;
    /**
     * If enabled, then a link can still be drawn from the port even if it is locked
     */
    allowLinksFromLockedPorts?: boolean;
}

export class DragNewLinkState extends AbstractDisplacementState<DiagramEngine> {
    port: PortModel = {} as PortModel;
    link: ProductLinkModel = {} as ProductLinkModel;
    config: DragNewLinkStateOptions;

    constructor(options: DragNewLinkStateOptions = {}) {
        super({ name: 'drag-new-link' });

        this.config = {
            allowLooseLinks: true,
            allowLinksFromLockedPorts: false,
            ...options
        };

        this.registerAction(
            new Action({
                type: InputType.MOUSE_DOWN,
                fire: (event: any) => {
                    this.port = this.engine.getMouseElement(event.event) as PortModel;

                    // HACK: This hack is to deal with the condition when you drag the mouse
                    // out of the workspace while dragging a link.  Then this.port does not get
                    // set correctly - SAB
                    if(!this.port || !this.port.createLinkModel) {
                        return;
                    }

                    if (!this.config.allowLinksFromLockedPorts && this.port.isLocked()) {
                        this.eject();
                        return;
                    }

                    this.link = this.port.createLinkModel() as ProductLinkModel;

                    if(this.link.getTargetPort()) {
                        this.port.removeLink(this.link);
                        if(this.port === this.link.getSourcePort()) {
                            const newSourcePort = this.link.getTargetPort();

                            this.link.clearPort(this.link.getTargetPort());

                            this.link.setSourcePort(newSourcePort);
                            this.link.setPoints(reverse(this.link.getPoints()));

                            this.engine.getModel().removeLink(this.link);
                            this.engine.getModel().addLink(this.link);
                        }
                    } else {
                        this.link.setSourcePort(this.port);
                        this.engine.getModel().addLink(this.link);
                        this.port.reportPosition()
                    }

                    this.link.setSelected(true);
                }
            })
        );

        this.registerAction(
            new Action({
                type: InputType.MOUSE_UP,
                fire: (event: any) => {
                    const model = this.engine.getMouseElement(event.event);

                    // check to see if we connected to a new port
                    if (model instanceof PortModel) {
                        if (this.port.canLinkToPort(model)) {
                            this.link.setTargetPort(model);
                            model.reportPosition();
                            this.engine.repaintCanvas();
                            return;
                        }

                    }

                    if (this.isNearbySourcePort(event.event) || !this.config.allowLooseLinks) {
                        this.link.remove();
                        this.engine.repaintCanvas();
                    }

                }
            })
        );
    }

    /**
     * Checks whether the mouse event appears to happen in proximity of the link's source port
     * @param event
     */
    isNearbySourcePort({ clientX, clientY }: MouseEvent): boolean {
        const sourcePort = this.link.getSourcePort();
        const sourcePortPosition = this.link.getSourcePort().getPosition();

        return (
            clientX >= sourcePortPosition.x &&
            clientX <= sourcePortPosition.x + sourcePort.width &&
            (clientY >= sourcePortPosition.y && clientY <= sourcePortPosition.y + sourcePort.height)
        );
    }

    /**
     * Calculates the link's far-end point position on mouse move.
     * In order to be as precise as possible the mouse initialXRelative & initialYRelative are taken into account as well
     * as the possible engine offset
     */
    fireMouseMoved(event: AbstractDisplacementStateEvent): any {
        const portPos = this.port.getPosition();
        const zoomLevelPercentage = this.engine.getModel().getZoomLevel() / 100;
        const engineOffsetX = this.engine.getModel().getOffsetX() / zoomLevelPercentage;
        const engineOffsetY = this.engine.getModel().getOffsetY() / zoomLevelPercentage;
        const initialXRelative = this.initialXRelative / zoomLevelPercentage;
        const initialYRelative = this.initialYRelative / zoomLevelPercentage;
        const linkNextX = portPos.x - engineOffsetX + (initialXRelative - portPos.x) + event.virtualDisplacementX;
        const linkNextY = portPos.y - engineOffsetY + (initialYRelative - portPos.y) + event.virtualDisplacementY;

        this.link.getLastPoint().setPosition(linkNextX, linkNextY);
        this.engine.repaintCanvas();
    }
}
