import {DiagramEngine, PortModel} from '@projectstorm/react-diagrams';
import {AbstractModelFactory} from '@projectstorm/react-canvas-core';
import {ProductPortModel} from "./ProductPortModel";

export class ProductPortFactory extends AbstractModelFactory<PortModel, DiagramEngine> {

    constructor() {
        super('product-port');
    }

    generateModel(event: any): PortModel {
        return new ProductPortModel(event?.initialConfig || {});
    }
}
