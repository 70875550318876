import * as React from 'react'
import styled from "@emotion/styled";
import {SaveButton} from "./SaveButton";
import {LoadButton} from "./LoadButton";
import {Button} from '@material-ui/core'
import {DonateButton} from "./DonateButton";
import {HelpTicker} from "./HelpTicker";
import {CheckDesignButton} from "./CheckDesignButton";
import {HelpButton} from "./HelpButton";
import {IsDemo} from "../IsDemo";
import {LoginButton} from "./LoginButton";

const HeaderDiv = styled.div`
		display: flex;
		flex-direction: row;
		background: rgb(30, 30, 30);
		flex-grow: 0;
		flex-shrink: 0;
		color: white;
		font-family: Helvetica, Arial, sans-serif;
		padding: 10px;
		align-items: center;
	`;

const e = ['t', 'wo', 'blo', 'kesaflo', 'at', '@g', 'mail', '.c', 'om'];

export const Header: React.FC = () => (
    <HeaderDiv>
        <div style={{whiteSpace: 'nowrap'}}>
            <SaveButton/>
            <LoadButton/>
            <Button style={{marginLeft: 10}} variant="contained" color="primary" size="small" href={`mailto:${e.join('')}`}>
                Contact us
            </Button>
            <IsDemo>
                {(isDemo) => isDemo ? null : <DonateButton/>}
            </IsDemo>
        </div>
        <div style={{flex: 1, paddingLeft: 10, paddingRight: 10}}>
            <IsDemo>
                {(isDemo) => isDemo ? null : <HelpTicker/>}
            </IsDemo>
        </div>
        <div>
            <CheckDesignButton/>
            <HelpButton />
            <LoginButton />
        </div>
    </HeaderDiv>
);

