import {PortWidget} from "@projectstorm/react-diagrams-core";
import {ProductPortModel} from "../model/ProductPortModel";
import {engine} from "../model/MyEngine";
import * as React from "react";
import {useState} from 'react';

interface ProductPortModelProps {
    port: ProductPortModel
    visible: boolean
}

export const Port = ({port, visible}: ProductPortModelProps) => {

    return (
        <PortWidget
            style={{
                position: 'absolute',
                backgroundColor: visible ? 'rgba(200,200,200,0.2)' : undefined,
                ...port.relativePosition
            }}
            key={port.getName()}
            port={port}
            engine={engine}
        >
            <PortDiv port={port} />
        </PortWidget>
    )
};

const PortDiv = ({port}: { port: ProductPortModel}) => {
    const [mouseOver, setMouseOver] = useState(false);

    const style = {
        width: port.width,
        height: port.height,
        zIndex: 10,
        borderRadius: 2,
        cursor: 'pointer',
        backgroundColor: mouseOver ? 'rgba(255,255,255,0.5)' : undefined
    };


    return (
        <div style={style} onMouseOver={() => setMouseOver(true)} onMouseOut={() => setMouseOver(false)}/>
    )
};