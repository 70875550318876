import React from 'react'
import {engine} from "../model/MyEngine";
import {Button} from "../overloaded/Button";

const showHelp = () => {engine.fireEvent({}, 'showHelp')};

export const HelpButton = () => (
    <Button onClick={showHelp}>Help</Button>
);

