import {Button} from "@material-ui/core";
import * as React from "react";
import {engine, repaintCanvas} from "../model/MyEngine";

export const CheckDesignButton = () => (
    <Button variant="contained" size="small" onClick={checkDesign}>Check design</Button>
);

const checkDesign = () => {
    engine.getModel().clearSelection();
    repaintCanvas();
    engine.getModel().checkDesign();
};