const PORT_SIZE = 8;


export const AllAroundPortFactory = ({name, iconHeight, iconWidth, offset = 8}: {name: string, iconHeight: number, iconWidth: number, offset?: number}) => {
    const ports = [];
    for(let i = iconHeight - offset; i > 0; i-=10) {
        ports.push({
            name: `${name}-port-left-${i}`,
            height: PORT_SIZE,
            width: PORT_SIZE * 2,
            position: {
                left: -PORT_SIZE,
                top: i
            }
        });
        ports.push({
            name: `${name}-port-right-${i}`,
            height: PORT_SIZE,
            width: PORT_SIZE * 2,
            position: {
                right: -PORT_SIZE,
                top: i
            }
        })
    }

    for(let i = iconWidth - offset; i > 0; i-=10) {
        ports.push({
            name: `${name}-port-top-${i}`,
            height: PORT_SIZE * 2,
            width: PORT_SIZE,
            position: {
                top: -PORT_SIZE,
                left: i
            }
        });

        ports.push({
            name: `${name}-port-bottom-${i}`,
            height: PORT_SIZE * 2,
            width: PORT_SIZE,
            position: {
                bottom: -PORT_SIZE,
                left: i
            }
        })
    }

    return ports;
};

