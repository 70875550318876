import * as React from 'react';
import {LabelPositions, ProductNodeModel} from "../../model/ProductNodeModel";
import {TextField} from "../../overloaded/form/TextField";
import {ActionButtons} from "./ActionButtons";
import {SelectField} from "../../overloaded/form/SelectField";
import {IfDefined} from "../../IfDefined";
import {Amazon} from "../../data/productList";
import {AmazonLearnMoreBtn} from "../../amazon/AmazonLearnMoreBtn";
import IconButton from "@material-ui/core/IconButton";
import RotateLeft from "@material-ui/icons/RotateLeft";
import RotateRight from "@material-ui/icons/RotateRight";

interface NodePropertiesProps {
    node: ProductNodeModel
}


export const ProductNodeProperties = ({node}: NodePropertiesProps) => {

    const rotate = (deg: number) => () => {
        let newRotation = node.rotation + deg;
        newRotation === 360 && (newRotation = 0);
        newRotation < 0 && (newRotation = 270);
        node.setRotation(newRotation);
    };

    const SpecWidget = node.product.specWidget || (() => null);

    return (
        <div key={node.getOptions().id}>
            <ActionButtons node={node}/>

            <div style={{marginBottom: 15}}>
                <TextField label="Label" defaultValue={node.product?.icon?.label} onChange={(ev) => node.setLabel(ev.target.value)}/>

                <span style={{paddingLeft: 20}}>
                    <SelectField inline defaultValue={node.product?.icon?.labelPosition} onChange={(ev) => node.setLabelPosition(ev.target.value)} options={LabelPositions}/>
                </span>
            </div>
            <label>Rotate: </label>
            <IconButton aria-label="rotate left" component="span" onClick={rotate(-90)}>
                <RotateLeft/>
            </IconButton>
            <IconButton aria-label="rotate right" component="span" onClick={rotate(90)}>
                <RotateRight/>
            </IconButton>
            <SpecWidget product={node.product}/>


            <div style={{padding: 5}}>
                {node.product.brand && <div style={{fontSize: '1.5em'}}>{node.product.brand}</div>}
                {node.product.description && <div style={{fontSize: '1.2em'}}>{node.product.description}</div>}
                {node.product.modelNo && <div>Model: {node.product.modelNo}</div>}
                <IfDefined value={node.product.amazon}>
                    {(amazon: Amazon) => (
                        <AmazonLearnMoreBtn amazon={amazon}/>
                    )}
                </IfDefined>
            </div>
        </div>
    );
};



