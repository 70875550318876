import * as React from "react";
import {useEffect} from "react";
import {CanvasWidget} from "@projectstorm/react-canvas-core";
import {useForceRender} from "../useForceRender";

interface DrawingCanvasProps {
    engine: any
}

export const DrawingCanvas: React.FC<DrawingCanvasProps> = ({engine}) => {
    const forceUpdate = useForceRender();

    useEffect(() => {
        const listener = engine.registerListener({
            modelChanged: forceUpdate
        });
        return () => listener.deregister();
    });

    useEffect(() => {
        const listener = engine.getModel().registerListener({
            nodesUpdated: forceUpdate,
            linksUpdated: forceUpdate
        });
        return () => listener.deregister();
    });

    return (
        <CanvasWidget key={Date.now()} engine={engine}/>
    )
};



